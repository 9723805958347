import { FormikErrors, FormikTouched } from 'formik';
import { memo } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { formikRadioFieldStyle } from '../style/formikRadioFieldStyle';
// import { RadioGroup } from '@mui/material';
// import './styling.css';
import { Box, Grid, Typography } from '@mui/material';
import { useAppSelector } from 'hooks/reduxHooks';

const sigleLineStyle = {
  display: "flex",
  flexWrap: "nowrap",
}
const FormikRadioField = ({
  checkFormStyle,
  checkFreezAcc= false,
  col = 6,
  fieldName,
  fields,
  formik,
  label,
  nestedFieldName,
  required = false,
  singleLine = false,
  style
}: FormikRadioFieldProps) => {
  const formikTouch = formik.touched as never;
  const formikError = formik.errors as never;

  let FieldError = '';
  let FieldTouch = false;
  let NestedFieldError = '';
  let NestedFieldTouch = false;

  if (nestedFieldName) {
    const nestedFieldErrorSplitting = formikError[fieldName?.split('.')[0]];
    const nestedFieldTouchSplitting = formikTouch[fieldName?.split('.')[0]];
    if (nestedFieldErrorSplitting && nestedFieldTouchSplitting) {
      NestedFieldError = nestedFieldErrorSplitting[nestedFieldName];
      NestedFieldTouch = nestedFieldTouchSplitting[nestedFieldName];
    }
  } else {
    FieldError = formikError[fieldName];
    FieldTouch = formikTouch[fieldName];
  }

  const { userAccountDetails } = useAppSelector(state => state.auth);

  const isFreezAcc = checkFreezAcc ? userAccountDetails?.restriction?.accountFreeze : false
  
  return (

    <>
      <Grid item xs={12}>
        {label && (
          <Typography sx={{ mb: "20px" }} variant='body2'>
            {label}
            {required && <span className='ml-1 text-danger'>*</span>}
          </Typography>
        )}
      </Grid >

      <Box
        sx={{  display: "flex", flexDirection: singleLine ? "row" : "column", flexWrap: 'wrap', gap: singleLine ? "14px" : "12px", ".form-check-label": { ml: "12px", mt: 0 } ,...style, }}
      >
        {fields.map(val => (
          <Box key={val.label} >
            <Form.Check
              key={val.label}
              checked={formik.values[fieldName] === val.value}
              disabled={!!val.disabled || isFreezAcc}
              id={val?.label?.toString() + label?.toString()}
              label={val.label}
              name={fieldName}
              onChange={formik.getFieldProps(fieldName).onChange}
              style={{...formikRadioFieldStyle.formCheckV1 , ...checkFormStyle}}
              type='radio'
              value={val.value}
            />
          </Box>
        ))}
      </Box>

      {FieldTouch && FieldError && (
        <span className='ml-2 mt-1 text-danger'>{FieldError}</span>
      )}
      {NestedFieldTouch && NestedFieldError && (
        <span className='ml-2 mt-1 text-danger'>{NestedFieldError}</span>
      )}
    </>

  );
};

export default memo(FormikRadioField);

interface FormikRadioFieldProps {
  col?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  disabled?: boolean;
  fieldName: string;
  nestedFieldName?: string;
  fields: Array<{ label: string; value: string; disabled?: boolean }>;
  formik: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getFieldProps: any;
    touched: FormikTouched<unknown>;
    errors: FormikErrors<unknown>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    values: any;
  };
  label?: string;
  required?: boolean;
  singleLine?: boolean;
  style?: any;
  checkFormStyle?:any;
  checkFreezAcc?: boolean;
  
}
