import React, { useState } from 'react';
import GenerateIdRaast from './generateIdRaast';
import GenerateIdViewRaast from './generateIdViewRaast';
import GenerateRaastError from './generateErrorRaast';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { onGenerateRaastId, setRaastId } from 'redux/actions';
import { useAppLoading } from 'hooks/appLoading';
import Loader from 'jsx/components/Shared/Loader';

const Index = ({ categoryAcc, formik, goalId, goalPostId, originalAmt, previousStep }: any) => {
    const dispatch = useAppDispatch();
    const { userAccountDetails } = useAppSelector(state => state.auth);
    const loading = useAppLoading('onGenerateRaastId');
    const [status, setStatus] = useState<string | false>(false);
    const [showErrMess, setShowErrMess] = useState(false);

    const handleGenerateRaastId = () => {
        const data = {
            "cashFundInvestmentId": goalPostId,
            "goalId": null,
            "grossAmount": originalAmt,
            "userId": userAccountDetails?.userId,
            "cnic": userAccountDetails?.identityCard?.identityCardNumber,
            "accountHolderName": userAccountDetails?.userName
        };

        // Handle Success Callback
        const onSuccessCallBack = (response: any, message: any) => {
            if (response) {
                dispatch(setRaastId(response));
            } else {
                setStatus("Raast ID is missing or invalid.");
                setShowErrMess(true);
            }
        };

        // Handle Fail Callback
        const onFail = (message: string, response:any) => {
            console.log(message , response)
            setStatus(message);
            setShowErrMess(true);
        };

        dispatch(onGenerateRaastId(data, onSuccessCallBack, onFail));
    };

    // Render based on status and showErrMess
    return (
        <>
            {loading && <Loader title="Please wait. This can take up to 10 seconds." />}
            {/* {showErrMess || (status && !userAccountDetails?.raastPseudoIban) ? (
                <GenerateRaastError message={status || "An error occurred."} />
            ) : ( */}

            {userAccountDetails?.raastPseudoIban ? (
                <GenerateIdViewRaast originalAmt={originalAmt} previousStep={previousStep} raastId={userAccountDetails?.raastPseudoIban} />
            ) : (
                <GenerateIdRaast handleGenerateRaastId={handleGenerateRaastId} showErrMess={showErrMess} />
            )
            }
            
        </>
    );
};

export default Index;