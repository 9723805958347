import AIIN from './AIIN.png';
import ABPA from './ABPA.png';
import ASCM from './ASCM.png';
import BAHL from './BAHL.png';
import ALFH from './ALFH.png';
import BKIP from './BKIP.png';
import KHYB from './KHYB.png';
import BPUN from './BPUN.png';

import CITI from './CITI.png';
import DUIB from './DUIB.png';
import FAYS from './FAYS.png';
import SBPP from './SBPP.png';
import FWOM from './FWOM.png';
import HABB from './HABB.png';
import MPBL from './MPBL.png';
import JSBL from './JSBL.png';

import MUCB from './MUCB.png';
import MCIB from './MCIB.png';
import MEZN from './MEZN.png';
import NBPA from './NBPA.png';
import SAMB from './SAMB.png';
import SAUD from './SAUD.png';
import SIND from './SIND.png';
import SMES from './SMES.png';

import SONE from './SONE.png';
import SCBL from './SCBL.png';
import SUMB from './SUMB.png';
import TMFB from './TMFB.png';
import UNIL from './UNIL.png';
import ZTBL from './ZTBL.png';

import DefaultRoundedBankIcon from './defaultRoundedIcon.png';

// Export all icons
export const AllBankIcon: Record<string, any> = {
    AIIN,
    ABPA,
    ASCM,
    BAHL,
    ALFH,
    BKIP,
    KHYB,
    BPUN,
    CITI,
    DUIB,
    FAYS,
    SBPP,
    FWOM,
    HABB,
    MPBL,
    JSBL,
    MUCB,
    MCIB,
    MEZN,
    NBPA,
    SAMB,
    SAUD,
    SIND,
    SMES,
    SONE,
    SCBL,
    SUMB,
    TMFB,
    UNIL,
    ZTBL,
    DefaultRoundedBankIcon,
};
