import LoadingButton from '@mui/lab/LoadingButton'
import { useAppSelector } from 'hooks/reduxHooks';
import React from 'react'

const buttonCustomStyles = {
    // minHeight: "42px",
    // padding: { lg: "10px 16px", md: "0 !important", xs: "0 !important" },
    minWidth: "118px",
    fontWeight: 500,
    fontSize: "0.875rem",
    '&.Mui-disabled': {
        backgroundColor: "#EAECF0",
        color: "#98A2B3"
    }
}

const LoadingBtnCustom = ({ btnTitle = `Let's Invest`, checkFreezAcc = false, color = "secondary", disabled, fullWidth = false, handleOnClick, loading, size = "large", style, type, variant = 'contained' }: any) => {

    const { userAccountDetails } = useAppSelector(state => state.auth);

    const isFreezAcc = checkFreezAcc ? userAccountDetails?.restriction?.accountFreeze : false

    const isbtndisabled = disabled || isFreezAcc

    return (
        <LoadingButton
            disableElevation
            loading={loading}
            sx={{ ...buttonCustomStyles, borderRadius: "100px", textTransform: "none", ...style }}
            variant={variant}
            color={color}
            onClick={handleOnClick}
            disabled={isbtndisabled}
            size={size}
            fullWidth={fullWidth}
            type={type}
        >
            {btnTitle}
        </LoadingButton>
    )
}


export default LoadingBtnCustom