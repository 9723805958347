import * as React from 'react';
import { Box, Container, Grid, Typography, Tabs, Tab, FormControl, InputAdornment, FormLabel, Button, IconButton, List, ListItem, useTheme } from '@mui/material'
import Modal from '@mui/material/Modal';
import FileIcon from "../../../Latest/Images/fileIcon.png"
import { ModalStyle } from '../Style/modalStyle';
import CloseIcon from '@mui/icons-material/Close';
import { PayNowStyles } from 'Latest/PayNow/style';
import { useFormik } from 'formik';
import { UserBankSubmitSchema } from 'validate/paymnet-options/bank-transfer';
import SelectField from '../../../jsx/components/Shared/FormikSelectFieldLatestV1';
import { useAppLoading } from 'hooks/appLoading';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { onGetBanks, onPostUserBankAccount, postUserAcc } from 'redux/reducers/sharedStateData';
import InputField from 'jsx/components/Shared/V1/FormikInputFieldLatestV1';
import useStyles from 'Latest/ComponentV1/CustomMakeStyle/CustomButtonNeutral';
import { IbanBankValidator } from 'utils/validator';
import { tokens } from 'theme';
import LoadingButton from '@mui/lab/LoadingButton';
import ButtonRowV1 from 'Latest/ComponentV1/ButtonRowV1';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: "95%", sm: "80%", md: "auto", lg: 700 },
    maxHeight: "90%",
    bgcolor: 'background.paper',
    boxShadow: "0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)",
    borderRadius: "12px",
    p: "24px",
    overflow: "auto"
};

export default function AddPayment({ handleClose, handleConfirm, handleOpen, open }: any) {

    const { banksList } = useAppSelector(state => state.sharedData);
    const dispatch = useAppDispatch();
    const loading = useAppLoading('sharedStateData');
    const classes = useStyles();

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    React.useEffect(() => {
        dispatch(onGetBanks())
    }, [])

    const defaultValues = {
        accountNumber: '',
        accountTitle: '',
        bank: null,
        bankID: ''
    };

    const Formik = useFormik({
        enableReinitialize: true,
        initialValues: defaultValues,
        onSubmit: (values: OnlineBankFormValues) => {
            const { accountNumber, accountTitle, bankID } = values;
            const body: postUserAcc = {
                accountTitle,
                accountNumber,
                bankName: bankID
            }
            const onSuccess = () => {
                handleConfirm();
                Formik.resetForm()
            }

            const onFail = () => {
                console.log("onFail")
            }

            dispatch(onPostUserBankAccount(body, onSuccess, onFail));
            return;
            // eslint-disable-next-line no-console
        },
        validationSchema: UserBankSubmitSchema,
    });

    React.useEffect(() => {
        let modifiedValue: any = Formik.values.accountNumber || '';

        modifiedValue = modifiedValue.toUpperCase();
        modifiedValue = modifiedValue.replace(/\s/g, '');

        Formik.setFieldValue('accountNumber', modifiedValue)

    }, [Formik.values.accountNumber])


    React.useEffect(() => {
        handleBankValidator(IbanBankValidator(Formik?.values?.accountNumber))
    }, [Formik?.values?.accountNumber])

    const handleBankValidator = (bankValidator: any) => {
        let filterBankDetail: any = []
        filterBankDetail = bankValidator?.length > 0 ? banksList?.filter((item) => item?.value == bankValidator) : []
        if (filterBankDetail.length > 0) {
            Formik.setFieldValue('bank', filterBankDetail[0]);
            Formik.setFieldValue('bankID', filterBankDetail[0]?.label);
            filterBankDetail = []
        }
        // else {
        //     Formik.setFieldValue('bank', null);
        //     Formik.setFieldValue('bankID', "");
        //     filterBankDetail = []
        // }
    }

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Container disableGutters maxWidth={"md"} sx={{ ...style }} >
                    {/* <Box sx={{ position: "absolute", right: 32, top: 32 }}>
                        <IconButton aria-label="upload picture" component="label">
                            <CloseIcon onClick={handleClose} />
                        </IconButton>
                    </Box> */}

                    {/* <Grid item xs={12} sx={{ mb: "16px" }}>
                        <img src={FileIcon} />
                    </Grid> */}

                    <Grid container spacing={2}>


                        <Grid item xs={12} >
                            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <Typography variant='h6' color={"#422E84"}>Add bank account</Typography>
                                <IconButton aria-label="upload picture" component="label">
                                    <CloseIcon onClick={handleClose} />
                                </IconButton>
                            </Box>
                            {/* <Typography variant='body2' color={colors.grey[600]}>Please enter your own account details</Typography> */}
                        </Grid>

                        <Grid item xs={12}>
                            <SelectField
                                // required
                                fieldName='bank'
                                label='Select bank'
                                fields={banksList}
                                formik={Formik}
                                placeholder={"Select Bank"}
                                onChange={e => {
                                    const selectedField = banksList.filter(i => i.value == e);
                                    Formik.setFieldValue('bank', selectedField[0]);
                                    Formik.setFieldValue('bankID', selectedField[0].label);
                                }}
                                value={Formik.values.bank}
                            />
                        </Grid>

                        <Grid item md={12} xs={12} >
                            <InputField
                                // required
                                upperCaseEnable
                                removeWhitespace
                                label='Account number / IBAN'
                                formik={Formik}
                                name='accountNumber'
                                placeholder='Enter details'
                            />
                            {/* <Grid item sx={{ pt: "5px" }}>
                                <Typography variant='h6'>Format <Typography component={"span"} variant='h6' sx={{ fontWeight: 600 }} >BBBB-ACCNUMBER</Typography></Typography>
                            </Grid> */}
                        </Grid>

                        <Grid item md={12} xs={12} sx={{ mb: "24px" }}>
                            <InputField
                                // required
                                formik={Formik}
                                label={"Account title"}
                                name='accountTitle'
                                placeholder='Enter your account title'
                                type='text'
                                style={{ textTransform: 'capitalize' }}
                            />
                        </Grid>


                        <ButtonRowV1 btnTitleBack={"Cancel"} btnTitleNext={"Submit"} handleBack={handleClose} handleNext={Formik.submitForm} checkFreezAcc={true}  />

                        {/* <Grid item xs={6} >
                            <Button disableElevation fullWidth size={"large"} variant='outlined' className={classes.neutralButton} onClick={handleClose}>Cancel</Button>
                        </Grid>

                        <Grid item xs={6} >
                            <LoadingButton disableElevation fullWidth loading={loading} size={"large"} variant='contained' color='secondary' onClick={Formik.submitForm}>Submit</LoadingButton>
                        </Grid> */}

                    </Grid>

                </Container>

            </Modal>
        </div>
    );
}

interface OnlineBankFormValues {
    accountNumber: string;
    accountTitle: string;
    bank: null;
    bankID: string;
}
