import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import React from 'react';

interface MethodToggleProps {
  method1Visible: boolean;
  setMethod1Visible: (visible: boolean) => void;
}

export const MethodToggle: React.FC<MethodToggleProps> = ({ method1Visible, setMethod1Visible }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {isMobile ? (
        // Mobile view: Clickable buttons
        <>
          <Button
            onClick={() => setMethod1Visible(true)}
            sx={{
              ml: '15px',
              backgroundColor: 'white', // Both buttons have white background
              border: method1Visible ? '2px solid #422E84' : '1px solid transparent', // Only selected button gets the border
              color: method1Visible ? '#422E84' : '#616A75', // Text color changes
              width: '40%',
              borderRadius: '8px'
            }}
          >
            Method 1
          </Button>
          <Button
            onClick={() => setMethod1Visible(false)}
            sx={{
              ml: '35px',
              backgroundColor: 'white', // Both buttons have white background
              border: !method1Visible ? '2px solid #422E84' : '1px solid transparent', // Only selected button gets the border
              color: !method1Visible ? '#422E84' : '#616A75', // Text color changes
              width: '40%',
              borderRadius: '8px'
            }}
          >
            Method 2
          </Button>
        </>
      ) : null} {/* Add this closing tag for conditional rendering */}
    </Box>
  );
};
