import React, { useRef, useEffect } from 'react';
import { Box, Container, Grid, Typography, Tabs, Tab, FormControl, InputAdornment, FormLabel, Button, IconButton, List, ListItem, Divider } from '@mui/material'
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import { PayNowStyles } from 'Latest/PayNow/style';
import { useFormik } from 'formik';
import { UserBankSubmitSchema } from 'validate/paymnet-options/bank-transfer';
import SelectField from 'jsx/components/Shared/FormikSelectField';
import { useAppLoading } from 'hooks/appLoading';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { onGetBanks, onPostUserBankAccount, postUserAcc } from 'redux/reducers/sharedStateData';
import InputField from 'jsx/components/Shared/V1/FormikInputFieldLatestV1';
import { ModalStyle } from 'Latest/MyProfile/Style/modalStyle';
import { changePasswordSchema } from 'validate/auth/signup';
import { onPostPasswordChange } from 'redux/actions';
import ThankYouModal from 'Latest/MyProfile/Modal/thankYouModal';
import ErrModal from 'Latest/MyProfile/Modal/errModal';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import AvatarCard from './avatarCard';
import { settingStyles } from './style';
import { ToastContainer, toast } from 'react-toastify';
import LoadingBtnCustom from 'Latest/ComponentV1/LoadingBtnCustom';
import { passwordMaxLength } from '../../constants/index';

export default function AddPayment() {
    const [openThankModal, setOpenThankModal] = React.useState(false);
    const [openErrModal, setOpenErrModal] = React.useState(false);
    const dispatch = useAppDispatch();
    const { userEmail } = useAppSelector(state => state.auth);
    React.useEffect(() => {
        dispatch(onGetBanks())
    }, [])

    const defaultValues = {
        email: userEmail || "",
        currentPassword: '',
        newPassword: '',
        confirmNewPassword: "",
        currentPasswordShow: true,
        newPasswordShow: true,
        confirmNewPasswordShow: true
    };

    const Formik = useFormik({
        enableReinitialize: true,
        initialValues: defaultValues,
        onSubmit: (values: any) => {

            const { confirmNewPassword, currentPassword, email, newPassword } = values;
            const body = { confirmNewPassword, currentPassword, email, newPassword }

            const onSuccess = () => {
                // setOpenThankModal(true)
                Formik.resetForm()
                toast.success('Password changed successfully', {
                    autoClose: 5000,
                    closeOnClick: true,
                    draggable: true,
                    hideProgressBar: false,
                    pauseOnHover: true,
                    position: 'top-right',
                    progress: undefined
                });
                return;
            }

            const onFail = (message: string, response: any) => {
                console.log(message, response.status, "OPOOP")
                setOpenErrModal(true)
            }

            dispatch(onPostPasswordChange(body, onSuccess, onFail));
            return;
            // eslint-disable-next-line no-console
        },
        validationSchema: changePasswordSchema,
    });

    const ContentSuccess = () => {
        return (
            <Grid item xs={12} sx={{ pt: "8px" }}>
                <Typography sx={ModalStyle.headStyle}>Your Password has been changed!</Typography>
                <Typography sx={ModalStyle.subheadStyle}>It is important to remember that passwords should be kept confidential and changed regularly to protect against security breaches. </Typography>
            </Grid>
        )
    }

    const ContentFailed = () => {
        return (
            <Grid item xs={12} sx={{ pt: "8px" }}>
                <Typography sx={ModalStyle.headStyle}>Oops!</Typography>
                <Typography sx={ModalStyle.subheadStyle}>"The password you entered is incorrect. Please try again."</Typography>
            </Grid>
        )
    }

    useEffect(() => {
        Formik.setFieldValue('newPassword', Formik.values.newPassword.trim())
        Formik.setFieldValue('confirmNewPassword', Formik.values.confirmNewPassword.trim())
        Formik.setFieldValue('currentPassword', Formik.values.currentPassword.trim())
    }, [Formik.values])

    const loading = useAppLoading('auth');
    return (
        <>
            <ToastContainer />
            <ThankYouModal handleOpen={() => setOpenThankModal(true)} open={openThankModal} handleClose={() => setOpenThankModal(false)} Content={ContentSuccess} />
            <ErrModal handleOpen={() => setOpenErrModal(true)} open={openErrModal} handleClose={() => setOpenErrModal(false)} Content={ContentFailed} />

            <Grid item xs={12}>
                <Box sx={{ mb: { xs: "27px", md: "48px" } }}>
                    <AvatarCard />
                </Box>

                <Divider sx={{ my: "20px", borderColor: "#EAECF0" }} />

                <>
                    <Grid item md={12} xs={12} >

                        <FormControl variant="outlined" className='payNowInputWrapper' sx={{ ...settingStyles.formControl }}>
                            <Typography sx={{ ...settingStyles.typo }} variant='body2'>Current Password <span style={{ color: "rgba(211, 47, 47, 1)" }}>*</span></Typography>
                            <InputField
                                required
                                // label={"Current Password"}
                                maxLength={Number(passwordMaxLength.maxLength)}
                                formik={Formik}
                                removeWhitespace={true}
                                name='currentPassword'
                                placeholder='Type...'
                                type={Formik.values.currentPasswordShow ? "password" : 'text'}
                                InputRight={() => <InputAdornment position="end" className='payNowInputAdornmentStyle'
                                    onClick={() => Formik.setFieldValue('currentPasswordShow', !Formik.values.currentPasswordShow)}
                                >
                                    {Formik.values.currentPasswordShow ? <VisibilityIcon sx={{ cursor: "pointer" }} /> : <VisibilityOffIcon sx={{ cursor: "pointer" }} />}
                                </InputAdornment>}
                            />
                        </FormControl>

                    </Grid>

                    <Divider sx={{ my: "20px", borderColor: "#EAECF0" }} />

                    <Grid item md={12} xs={12} >
                        <FormControl variant="outlined" className='payNowInputWrapper' sx={{ ...settingStyles.formControl }}>
                            <Typography sx={{ ...settingStyles.typo }} variant='body2'>New Password <span style={{ color: "rgba(211, 47, 47, 1)" }}>*</span></Typography>
                            <InputField
                                required
                                // label={"New Password"}
                                maxLength={Number(passwordMaxLength.maxLength)}
                                removeWhitespace={true}
                                formik={Formik}
                                name='newPassword'
                                placeholder='Type...'
                                type={Formik.values.newPasswordShow ? "password" : 'text'}
                                InputRight={() => <InputAdornment position="end" className='payNowInputAdornmentStyle'
                                    onClick={() => Formik.setFieldValue('newPasswordShow', !Formik.values.newPasswordShow)}
                                >
                                    {Formik.values.newPasswordShow ? <VisibilityIcon sx={{ cursor: "pointer" }} /> : <VisibilityOffIcon sx={{ cursor: "pointer" }} />}
                                </InputAdornment>}
                            />
                        </FormControl>
                    </Grid>

                    <Divider sx={{ my: "20px", borderColor: "#EAECF0" }} />

                    <Grid item md={12} xs={12} >
                        <FormControl variant="outlined" className='payNowInputWrapper' sx={{ ...settingStyles.formControl }}>
                            <Typography sx={{ ...settingStyles.typo }} variant='body2'>Confirm New Password <span style={{ color: "rgba(211, 47, 47, 1)" }}>*</span></Typography>
                            <InputField
                                required
                                // label={"Confirm New Password"}
                                maxLength={Number(passwordMaxLength.maxLength)}
                                removeWhitespace={true}
                                formik={Formik}
                                name='confirmNewPassword'
                                placeholder='Type...'
                                type={Formik.values.confirmNewPasswordShow ? "password" : 'text'}
                                InputRight={() => <InputAdornment position="end" className='payNowInputAdornmentStyle'
                                    onClick={() => Formik.setFieldValue('confirmNewPasswordShow', !Formik.values.confirmNewPasswordShow)}>
                                    {Formik.values.confirmNewPasswordShow ? <VisibilityIcon sx={{ cursor: "pointer" }} /> : <VisibilityOffIcon sx={{ cursor: "pointer" }} />}
                                </InputAdornment>}
                            />
                        </FormControl>


                    </Grid>
                </>

                <Divider sx={{ my: "20px", borderColor: "#EAECF0" }} />

                <Grid item xs={12} sx={{ display: "flex", justifyContent: "end" }}>
                    <LoadingBtnCustom loading={loading} btnTitle="Save Changes" handleOnClick={Formik.submitForm} checkFreezAcc={true} />
                </Grid>

            </Grid>

        </>
    );
}