/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable sort-keys */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ACCOUNT_MANAGEMENT_BASE_URL, USER_MANAGER_BASE_URL, AuthMsgs, INVITE_MAHANIERS_BASER_URL, WEALTH_API_BASE_URL, WEALTH_RAAST_PAYMENT_BASER_URL
} from '../../constants';
import { toast } from 'react-toastify';
import { paramRemover } from 'utils/paramFInder';
export const name = 'auth';

const initialState: InitialState = {
  IsAuthorized: false,
  access_token: "",
  refresh_token: "",
  expires_in: null,
  scopes: [""],
  userEmail: null,
  userId: "",
  isPhoneNumberVerified: '',
  isEmailVerified: '',
  clearEmailPassword: false,
  createUserId: null,
  goals: '',
  invitationDetails: {
    company_name: '',
    email: '',
    first_name: '',
    is_employer: false,
    last_name: ''
  },
  isAuth: false,
  isRiskProfileCompleted: '0',
  isSetUpComplete: false,
  isValidOtp: true,
  msg: '',
  otpUserId: null,
  otpVerified: false,
  user: {
    email: '',
    id: '',
    userId: '',
    user_name: '',
    phoneNumber: '',
    dob: '',
    token: '',
  },
  userAccountDetails: undefined,
  userAccountInfo: {
    name: '',
    clientUniqueIdentifier: '',
    isNewUser: undefined,
  },
  dashboardDetail: "",
  waitlistUsers: [],
  inviteesUsers: [],
  CnicImgFront: "",
  CnicImgBack: "",
  userTempToken: "",
  isGIAllowed: false,
  raastId: null
}

const authSlice = createSlice({
  initialState,
  name,
  reducers: {
    setWaitlistUsers: (state, action: PayloadAction<any>) => {
      state.waitlistUsers = action.payload
    },
    setInviteesUsers: (state, action: PayloadAction<any>) => {
      state.inviteesUsers = action.payload
    },
    clearSignupProcess: state => {
      state.msg = '';
      state.otpUserId = null;
      state.createUserId = null;
      state.isValidOtp = true;
    },
    forgotSuccess: state => {
      // state.msg = 'An email has been sent to your account for password reset.';
    },
    invitationSuccess: (state, action: PayloadAction<any>) => {
      state.msg =
        'Thank you for sharing your details, we will get in touch when it is your turn on the wait list.';
    },
    setEmail: (state, action: PayloadAction<any>) => {
      state.userEmail = action.payload
    },
    setUserId: (state, action: PayloadAction<any>) => {
      state.userId = action.payload
    },
    setUserTempToken: (state, action: PayloadAction<any>) => {
      state.userTempToken = action.payload
    },
    setOtpInvalid: (state) => {
      state.isValidOtp = false;
    },
    setClearEmailPassword: (state, action: PayloadAction<any>) => {
      state.clearEmailPassword = action.payload
    },
    loginSuccess: (state, action: PayloadAction<LoginSuccessDataPayload>) => {
      const {
        access_token,
        expires_in,
        refresh_token,
        scope
      } = action.payload;
      state.access_token = access_token;
      state.refresh_token = refresh_token;
      state.expires_in = expires_in;
      state.scopes = scope?.split(' ');
      state.isAuth = true;
    },
    setUserDetails: (state, action: PayloadAction<any>) => {
      const roles = action.payload.roles
      const isGIAllowed = roles?.length && roles?.some((e: any) => e?.name?.toLowerCase() === "GI_allowed"?.toLowerCase())
      state.user = action.payload;
      state.isGIAllowed = isGIAllowed;
    },
    setUserAccountInfo: (state, action: PayloadAction<any>) => {
      state.userAccountInfo = action.payload;
    },
    onGetUserAccountDetailsSuccess: (state, action: PayloadAction<any>) => {
      state.userAccountDetails = action.payload;
    },
    refreshTokenSuccess: (state, action: PayloadAction<LoginSuccessDataPayload>) => {
      const {
        access_token,
        expires_in,
        refresh_token,
      } = action.payload;
      state.access_token = access_token;
      state.refresh_token = refresh_token;
      state.expires_in = expires_in;
    },
    logout: state => {
      state.isAuth = false;
      state.isRiskProfileCompleted = false;
      state.scopes = [];
      state.access_token = null;
      state.refresh_token = null;
      state.expires_in = null;
      state.userAccountDetails = undefined;
      state.user = {
        email: '',
        id: '',
        userId: '',
        user_name: '',
        phoneNumber: '',
        dob: '',
        token: '',
      };
      state.userAccountInfo = {
        name: '',
        clientUniqueIdentifier: '',
        isNewUser: undefined
      }
    },
    registerSuccess: (state, action: PayloadAction<any>) => {
      state.userId = action.payload.id;
      state.userTempToken = action.payload.token;
      state.msg = AuthMsgs.otpVerify;
    },
    onSmsCodeVerifiactionSuccess: (state, action: PayloadAction<any>) => {
      state.msg = AuthMsgs.emailVerify;
    },
    onEmailVerifiactionSuccess: state => {
      state.isEmailVerified = "true";
    },
    onEmailVerifiactionFail: state => {
      state.isEmailVerified = "false";
    },
    onResendEmailSuccess: (state, action: PayloadAction<any>) => {
      toast.success('Email sent', {
        autoClose: 5000,
        closeOnClick: true,
        draggable: true,
        hideProgressBar: false,
        pauseOnHover: true,
        position: 'bottom-right',
        progress: undefined
      });
    },
    resendEmailCodeSuccess: state => {
      state.msg = 'An email has been sent to your account for password reset.';
    },
    onSmsCodResendSuccess: (state, action: PayloadAction<any>) => {
      toast.success('OTP is sent to your Registered Mobile Number', {
        autoClose: 5000,
        closeOnClick: true,
        draggable: true,
        hideProgressBar: false,
        pauseOnHover: true,
        position: 'bottom-right',
        progress: undefined
      });
    },
    onForgotPasswordSuccess: (state, action: PayloadAction<any>) => {
      toast.success('Email Sent', {
        autoClose: 5000,
        closeOnClick: true,
        draggable: true,
        hideProgressBar: false,
        pauseOnHover: true,
        position: 'bottom-right',
        progress: undefined,
        toastId: 'success1',
      });
    },
    onResetPasswordSuccess: (state, action: PayloadAction<any>) => {
      toast.success('Password changed successfully.', {
        autoClose: 5000,
        closeOnClick: true,
        draggable: true,
        hideProgressBar: false,
        pauseOnHover: true,
        position: 'bottom-right',
        progress: undefined,
        toastId: 'success1',
      });
      state.msg = 'Your Password is changed successfully.';
    },
    frontCnicImgAction: (state, action: PayloadAction<any>) => {
      state.CnicImgFront = action.payload;
    },
    backCnicImgAction: (state, action: PayloadAction<any>) => {
      state.CnicImgBack = action.payload;
    },
    setRaastId: (state, action: PayloadAction<any>) => {
      if (state.userAccountDetails) {
        state.userAccountDetails.raastPseudoIban = action.payload;
      }
    },
  }
});

export const {
  backCnicImgAction,
  clearSignupProcess: onClearSignupProcess,
  frontCnicImgAction,
  loginSuccess: setCurrentLogin,
  logout: onLogout,
  onEmailVerifiactionFail,
  onEmailVerifiactionSuccess,
  onForgotPasswordSuccess,
  onGetUserAccountDetailsSuccess,
  onResendEmailSuccess,
  onResetPasswordSuccess,
  onSmsCodResendSuccess,
  onSmsCodeVerifiactionSuccess,
  refreshTokenSuccess: setRefreshToken,
  registerSuccess,
  setClearEmailPassword,
  setEmail,
  setInviteesUsers,
  setOtpInvalid,
  setRaastId,
  setUserAccountInfo,
  setUserDetails,
  setUserId,
  setUserTempToken,
  setWaitlistUsers
} = authSlice.actions;

export default authSlice.reducer;

//? APIS ACTIONS
export const onRegister = (
  data: RegisterParams,
  onFail: (message: string, response: any) => void
) => ({
  payload: {
    apiName: "AuthRegister",
    data,
    method: 'post',
    onFail,
    onSuccess: registerSuccess,
    url: `${ACCOUNT_MANAGEMENT_BASE_URL}/api/UserAccount`,
    authorize: false
  },
  type: 'onRegister'
});
export const verifyResetPassToken = (
  data: VerifyResetPassToken,
  onSuccess: (message: string, response: any) => void,
  onFail: (message: string, response: any) => void
) => ({
  payload: {
    apiName: name,
    authorize: false,
    data,
    onFail,
    onSuccess,
    method: 'post',
    url: `${USER_MANAGER_BASE_URL}/api/Account/verify/token`
  },
  type: 'verifyResetPasswordToken'
});

export const resendSmsCode = (messageMethod:string ,userId: string, onSuccess: (message: string, response: any) => void, onFail: (message: string, response: any) => void) => ({
  payload: {
    apiName: name,
    authorize: false,
    errorToast:true,
    // data: {},
    method: 'post',
    onFail: onFail,
    // onSuccess: onSmsCodResendSuccess,
    onSuccess: onSuccess,
    url: `${USER_MANAGER_BASE_URL}/api/Account/${userId}/verification/${messageMethod}/resend`
  },
  type: 'resendSmsCode'
});

export const onVerifyEmail = (
  data: verifyEmailParams,
  onFail: (message: string, response: any) => void) => ({
    payload: {
      apiName: name,
      authorize: false,
      data,
      method: 'post',
      onFail,
      onSuccess: onEmailVerifiactionSuccess,
      url: `${USER_MANAGER_BASE_URL}/api/Account/${data.userId}/verification/email/confirm`
    },
    type: 'onVverifyEmail'
  });

export const resendEmailVerifcation = (
  userId: string,
  onFail: (message: string, response: any) => void,
  onSuccess?:boolean
) => ({
    payload: {
      apiName: name,
      authorize: false,
      // data,
      onFail,
      onSuccess: onSuccess?()=>{console.log('resend email successfully')}:onResendEmailSuccess,
      method: 'post',
      url: `${USER_MANAGER_BASE_URL}/api/Account/${userId}/verification/email/resend`
    },
    type: 'resendEmailVerifcation'
  });

export const verifySmsCode = (
  data: verifySmsCodeParams,
  onSuccess: (message: string, response: any) => void,
  onFail: (message: string, response: any) => void) => ({
    payload: {
      apiName: name,
      authorize: false,
      data,
      method: 'post',
      onFail,
      onSuccess: onSuccess,
      url: `${USER_MANAGER_BASE_URL}/api/Account/${data.userId}/verification/sms/confirm`
    },
    type: 'verifySmsCode'
  });

export const onLogin = (
  data: any,
  onFail: (message: string, response: any) => void
) => ({
  payload: {
    apiName: name,
    authorize: false,
    content_type: "application/x-www-form-urlencoded",
    data,
    method: 'post',
    onFail,
    onSuccess: setCurrentLogin,
    url: `${USER_MANAGER_BASE_URL}/api/Account/token`,
  },
  type: 'login'
});

export const onSubscription = (
  data: object,
  params:string,
  onSuccess: (message: any, response: any) => void,
  onFail: (message: any, response: any) => void
) =>  ({
  payload: {
    apiName: name,
    authorize: false,
    content_type: "application/json",
    data,
    method: 'post',
    onSuccess,
    onFail,
    url: `${INVITE_MAHANIERS_BASER_URL}/api/EmailOption/${params}`,
  },
  type: 'subscribtion'
})


export const userVerificationStatus = (data: userVerificationStatusParams,
  onSuccess: (message: string, response: any) => void,
) => ({
  payload: {
    apiName: "AuthVerificaton",
    authorize: false,
    method: 'get',
    onSuccess: onSuccess,
    url: `${ACCOUNT_MANAGEMENT_BASE_URL}/api/UserAccount/${data.userId}/verification?token=${data.token}`,
  },
  type: 'UserDetails'
});

export const UserDetails = () => ({
  payload: {
    apiName: name,
    method: 'get',
    onSuccess: setUserDetails,
    url: `${USER_MANAGER_BASE_URL}/api/Account`,
  },
  type: 'UserDetails'
});


export const getWaitlistUsers = () => ({
  payload: {
    apiName: name,
    method: 'get',
    onSuccess: setWaitlistUsers,
    url: `${INVITE_MAHANIERS_BASER_URL}/api/WaitList`,
  },
  type: 'waitlistUsers'
});

export const getInviteesUsers = () => ({
  payload: {
    apiName: name,
    method: 'get',
    onSuccess: setInviteesUsers,
    url: `${INVITE_MAHANIERS_BASER_URL}/api/Invitees`,
  },
  type: 'inviteesUsers'
});

export const submitWaitlistUser = (data: Record<string, any>, onSuccess: (message: string, response: any) => void, onFail: (message: string, response: any) => void) => ({
  payload: {
    authorize: false,
    apiName: name,
    data,
    method: 'post',
    onSuccess,
    onFail,
    url: `${INVITE_MAHANIERS_BASER_URL}/api/WaitList`,
  },
  type: 'submitWaitlistUser'
});

export const checkEmailInInvitees = (data: Record<string, any>, onSuccess: (message: string, response: any) => void, onFail: (message: string, response: any) => void) => ({
  payload: {
    authorize: false,
    apiName: name,
    data,
    method: 'post',
    onSuccess,
    onFail,
    url: `${INVITE_MAHANIERS_BASER_URL}/api/Invitees/CheckEmailInInvitees`,
  },
  type: 'checkEmailInInvitees'
});

export const getUserAccountInfo = (userId: string | undefined) => ({
  payload: {
    apiName: name,
    method: 'get',
    onSuccess: setUserAccountInfo,
    url: `${ACCOUNT_MANAGEMENT_BASE_URL}/api/UserAccount/${userId}/AccountInfo`,
  },
  type: 'getUserAccountInfo'
});

export const refreshToken = (data: any) => ({
  payload: {
    apiName: name,
    authorize: false,
    content_type: "application/x-www-form-urlencoded",
    data,
    method: 'post',
    onSuccess: setRefreshToken,
    url: `${USER_MANAGER_BASE_URL}/api/account/token`,
  },
  type: 'refreshToken'
});

export const onSubmitAccountSetup = (data: Record<string, any>) => ({
  payload: {
    apiName: name,
    data,
    method: 'post',
    //url: `${ACCOUNT_MANAGEMENT_BASE_URL}/api/UserAccount/${data.userId}/details`,
    url: `${ACCOUNT_MANAGEMENT_BASE_URL}/api/UserAccount/details`,
  },
  type: 'onSubmitAccountSetup'
});

export const onSubmitDocuments = (data: any, onSuccess: (message: string, response: any) => void, onFail: (message: string, response: any) => void) => ({
  payload: {
    apiName: name,
    data,
    formData: true,
    method: 'post',
    onSuccess,
    onFail,
    url: `${ACCOUNT_MANAGEMENT_BASE_URL}/api/UserAccount/documents`,
    abortApi: true,
  },
  type: 'onSubmitDocuments'
});

export const onSubmitZakatDocuments = (data: any, onSuccess: (message: string, response: any) => void, onFail: (message: string, response: any) => void) => ({
  payload: {
    apiName: "zakaatDoc",
    data,
    formData: true,
    method: 'post',
    errorToast: true,
    onSuccess,
    onFail,
    url: `${ACCOUNT_MANAGEMENT_BASE_URL}/api/UserDocument/zakataffidavit`,
  },
  type: 'onSubmitZakatDocuments'
});

export const downloadZakatDocuments = (userId: string, onSuccess: (message: string, response: any) => void, onFail: (message: string, response: any) => void) => ({
  payload: {
    apiName: "downloadZakatDocuments",
    responseType: 'arraybuffer',
    method: 'get',
    onSuccess,
    onFail,
    url: `${ACCOUNT_MANAGEMENT_BASE_URL}/api/UserDocument/zakataffidavit/?userId=${userId}`,
  },
  type: 'downloadZakatDocuments'
});

export const downloadWNineForm = (userId: string, onSuccess: (message: any, response: any) => void, onFail: (message: any, response: any) => void) => ({
  payload: {
    apiName: "downloadWNineForm",
    responseType: 'arraybuffer',
    method: 'get',
    onSuccess,
    onFail,
    url: `${ACCOUNT_MANAGEMENT_BASE_URL}/api/UserDocument/w9form/?userId=${userId}`,
  },
  type: 'downloadWNineForm'
});



export const downloadZakatDecFile = (param: string, onSuccess: (message: string, response: any) => void, onFail: (message: string, response: any) => void) => ({
  payload: {
    apiName: "downloadZakatDecFile",
    responseType: 'arraybuffer',
    method: 'get',
    onSuccess,
    onFail,
    url: `${WEALTH_API_BASE_URL}/api/mahaana/document/zakataffidavit/${param}`,
  },
  type: 'downloadZakatDecFile'
});

export const downloadAccountStatement = (urlParam:string,onSuccess: (message: string, response: any) => void ,onFail: (message: string, response: any) => void) => ({
  payload:{
    apiName: "downloadAccountStatement",
    responseType: 'arraybuffer',
    errorToast:true,
    method: 'get',
    onSuccess,
    onFail,
    url: `${WEALTH_API_BASE_URL}/api/mahaana/document/accountStatement${urlParam}`,
  },
  type: 'downloadAccountStatement'
});

export const onGetAccountSetup = (apiName: any = "auth") => ({
  payload: {
    apiName: apiName,
    method: 'get',
    onSuccess: onGetUserAccountDetailsSuccess,
    url: `${ACCOUNT_MANAGEMENT_BASE_URL}/api/UserAccount`,
  },
  type: 'onGetAccountSetup'
});

export const onForgotPassword = (
  data: ForgotParams,
  onFail: (message: string, response: any) => void) => ({
    payload: {
      apiName: name,
      authorize: false,
      data: { email: data.email },
      method: 'post',
      onFail,
      onSuccess: onForgotPasswordSuccess,
      url: `${USER_MANAGER_BASE_URL}/api/Account/reset`,
    },
    type: 'onForgotPassword'
  });

export const onResetPassword = (
  data: ResetPassParams,
  onFail: (message: string, response: any) => void) => ({
    payload: {
      apiName: name,
      authorize: false,
      data,
      method: 'post',
      onFail,
      onSuccess: onResetPasswordSuccess,
      url: `${USER_MANAGER_BASE_URL}/api/Account/${data.userId}/reset`,
    },
    type: 'onResetPassword'
  });

export const onPostPasswordChange = (data: ChangePassParams, onSuccess: (message: string, response: any) => void, onFail: (message: string, response: any) => void) => ({
  payload: {
    apiName: name,
    data,
    method: 'post',
    onSuccess: onSuccess,
    onFail: onFail,
    url: `${USER_MANAGER_BASE_URL}/api/Account/changePassword`,
  },
  type: 'onPostPasswordChange'
});

export const onContactUsMessage = (data: ContactUsMessageParams, onSuccess: (message: string, response: any) => void, onFail: (message: string, response: any) => void) => ({
  payload: {
    apiName: name,
    data,
    method: 'post',
    onSuccess: onSuccess,
    onFail: onFail,
    url: `${INVITE_MAHANIERS_BASER_URL}/api/Message`
  },
  type: 'contactUsMessage'
});

//OLD
export const onInvite = (data: InviteParams) => ({
  payload: {
    apiName: name,
    data,
    headers: { Authorization: process.env.REACT_APP_INVITE_TOKEN },
    method: 'post',
    url: 'UserInvitation/CreateUserInvitation'
  },
  type: 'invitation'
});

export const onConfirmUserInvitation = (data: ConfirmTokenParams) => ({
  payload: {
    apiName: name,
    data,
    method: 'post',
    url: 'UserInvitation/ConfirmEmplyeeInvitation'
  },
  type: 'confirmUserInvitation'
});

export const onGetUserInvitationData = (token: string | null) => ({
  payload: {
    apiName: name,
    method: 'get',
    url: `SignUp/GetUserInvitationData?token=${token}`
  },
  type: 'userInvitationData'
});

export const onResendEmailCode = (data: string) => ({
  payload: {
    apiName: name,
    data: { email: data },
    method: 'post',
    url: 'SignIn/ResendEmailCode'
  },
  type: 'onResendEmailCode'
});

export const onResendPhoneCodeHanele = (data: string) => ({
  payload: {
    apiName: name,
    authorize: false,
    data: { email: data },
    method: 'post',
    url: 'SignIn/ResendSmsCode'
  },
  type: 'onResendPhoneCodeHanele'
});


export const onSendPhoneOTP = (data: SendOTPParams) => ({
  payload: {
    apiName: name,
    method: 'get',
    url: `User/RegisterPhonenum?phonenumber=${data.phone}`
  },
  type: 'onSendPhoneOTP'
});

export const onGetAccountProfile = () => ({
  payload: {
    apiName: name,
    method: 'get',
    url: `User`
  },
  type: 'onGetAccountProfile'
});


export const onSendRiskProfile = (data: unknown) => ({
  payload: {
    apiName: name,
    data,
    method: 'post',
    url: 'RiskProfile/SaveRiskProfile'
  },
  type: 'submitRiskProfile'
});

export const onUpdateRiskProfile = (data: unknown) => ({
  payload: {
    apiName: name,
    data,
    method: 'put',
    url: 'RiskProfile/UpdateRiskProfile'
  },
  type: 'submitRiskProfile'
});

export const onAccountConfirmation = (data: unknown) => ({
  payload: {
    apiName: name,
    data,
    method: 'post',
    url: 'User/ConfirmUserAccount'
  },
  type: 'accountConfirmation'
});

export const onInviteConfirmation = (data: any) => ({
  payload: {
    apiName: name,
    data,
    method: 'post',
    url: 'User/ConfirmUserInvitation'
  },
  type: 'inviteConfirmation'
});

export const onGetInvitationStatus = (data: any) => ({
  payload: {
    apiName: name,
    data,
    method: 'post',
    url: 'User/GetInvitationStatus'
  },
  type: 'getInvitationStatus'
});

export const onGetFrontCnicImg = (onFail?: (message: string, response: any) => void, onSuccessCallback?: (message: string, response: any) => void) => ({
  payload: {
    apiName: name,
    onSuccess: frontCnicImgAction,
    onSuccessCallback,
    onFail,
    responseType: 'arraybuffer',
    method: 'get',
    url: `${ACCOUNT_MANAGEMENT_BASE_URL}/api/UserAccount/UserNicFront/identitydocument?cnicSide=UserNicFront`
  },
  type: 'onGetFrontCnicImg'
});

export const onGetBackCnicImg = (onFail?: (message: string, response: any) => void, onSuccessCallback?: (message: string, response: any) => void) => ({
  payload: {
    apiName: name,
    onSuccess: backCnicImgAction,
    onSuccessCallback,
    onFail,
    responseType: 'arraybuffer',
    method: 'get',
    url: `${ACCOUNT_MANAGEMENT_BASE_URL}/api/UserAccount/UserNicFront/identitydocument?cnicSide=UserNicBack`
  },
  type: 'onGonGetBackCnicImgetCNICIMG'
});

export const onPostNextKin = (data: any, onSuccess: (message: string, response: any) => void, onFail: (message: string, response: any) => void) => ({
  payload: {
    apiName: "postNextOfKin",
    data,
    onSuccess: onSuccess,
    onFail,
    errorToast: true,
    method: 'post',
    url: `${ACCOUNT_MANAGEMENT_BASE_URL}/api/Beneficiary`
  },
  type: 'onPostNextKin'
});

export const onPostSecondTier = (data: any, onSuccess: (message: string, response: any) => void, onFail: (message: string, response: any) => void) => ({
  payload: {
    apiName: "onPostSecondTier",
    data,
    formData: true,
    onSuccess,
    onFail: onFail,
    errorToast: true,
    method: 'post',
    url: `${ACCOUNT_MANAGEMENT_BASE_URL}/api/UserDocument/kyc/secondtier`
  },
  type: 'onPostSecondTier'
});

export const onPostFatcaAndCrsQuestions = (data: any , onSuccessFatca: (message: any, response: any) => void, onFailFatca: (message: any, response: any) => void) => ({
  payload: {
    apiName: name,
    method: 'put',
    data,
    FormData: true,
    onSuccess: onSuccessFatca,
    onFail: onFailFatca,
    url: `${ACCOUNT_MANAGEMENT_BASE_URL}/api/UserAccount/Fatca`
  },
  type: 'onPostFatcaAndCrsQuestions'
});

export const onGetKYCImg = (data: any, onSuccess: (message: string, response: any) => void) => ({
  payload: {
    apiName: "onGetKYCImg",
    onSuccess,
    method: 'get',
    responseType: 'arraybuffer',
    url: `${ACCOUNT_MANAGEMENT_BASE_URL}/api/UserDocument/kyc/secondtier/document?userId=${data.userId}&documentName=${data.documentName}`
  },
  type: 'onGetKYCImg'
});

export const onDeleteKYCDoc = (data: any) => ({
  payload: {
    apiName: "onDeleteKYCDoc",
    data,
    formData: true,
    method: 'patch',
    url: `${ACCOUNT_MANAGEMENT_BASE_URL}/api/UserDocument/kyc/secondtier/documents`
  },
  type: 'onDeleteKYCDoc'
});

export const onGenerateRaastId = (data: any, onSuccessCallBack: (message: any, response: any) => void , onFail: (message: any, response: any) => void) => ({
  payload: {
    apiName: "onGenerateRaastId",
     // onSuccess: setRaastId,
     onSuccess: onSuccessCallBack,
     onFail: onFail,
    data,
    method: 'post',
    url: `${WEALTH_RAAST_PAYMENT_BASER_URL}/api/PseudoIban/GeneratePaymentId`
  },
  type: 'onGenerateRaastId'
});

// ? Types
type User = {
  email: string;
  id?: string;
  userId?: any;
  user_name?: string;
  phoneNumber?: string;
  dob?: string;
  token?: string;
};
interface InitialState {
  access_token: string | null;
  clearEmailPassword: boolean;
  refresh_token: string | null;
  expires_in: number | null;
  scopes: Array<string>;
  userEmail: string | null;
  userId: string;
  isEmailVerified: string,
  isPhoneNumberVerified: string,
  createUserId: number | null;
  isAuth: boolean;
  msg: string;
  otpUserId: number | null;
  otpVerified: boolean;
  user: User;
  isRiskProfileCompleted: boolean | string;
  IsAuthorized: boolean;
  goals: string;
  userAccountDetails: Record<string, any> | undefined;
  isSetUpComplete: boolean;
  invitationDetails: InvitationDetails;
  isValidOtp: boolean;
  userAccountInfo: {
    name: string,
    clientUniqueIdentifier: string,
    isNewUser: boolean | undefined,
  };
  dashboardDetail: any,
  waitlistUsers?: any,
  inviteesUsers?: any,
  CnicImgFront?: any,
  CnicImgBack?: any,
  userTempToken?: string | undefined,
  isGIAllowed?: boolean,
  raastId: any
}
export interface InvitationDetails {
  first_name: string;
  last_name: string;
  email: string;
  is_employer: boolean;
  company_name: string;
}
export interface LoginSuccessPayload extends User {
  Data: LoginSuccessDataPayload;
}
export interface LoginSuccessDataPayload {
  // api_key: string;
  // is_risk_profile_completed: string;
  // role_type: string;
  // bank_account_process_started: boolean;
  // first_transaction_completed: boolean;
  // is_authorized: boolean;
  // goals: string;
  // is_setup_complete: boolean;
  // dob: string;
  // success: boolean;
  // message: string;
  access_token: string,
  refresh_token: string,
  expires_in: number,
  scope: string
}
export interface LoginParams {
  email: string;
  password: string;
}

export interface ContactUsMessageParams {
  email?: string;
  isActiveUser:boolean;
  name?: string;
  message: string;
}

export interface InviteParams {
  email: string;
  firstName: string;
  lastName: string;
}
export interface ConfirmTokenParams {
  token: string;
}

export interface RegisterParams {
  name: string;
  email: string;
  // firstName: string;
  // lastName: string;
  password: string;
  confirmPassword: string;
  phoneNumber: string;
  // dateOfBirth: any
}

export interface VerifyResetPassToken {
  userId: string;
  token: string;
}

export interface ForgotParams {
  email: string;
}
export interface ResetPassParams {
  userId: string;
  password: string;
  confirmPassword: string;
  token: string;
}
export interface ChangePassParams {
  email: string;
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
}
export interface SendOTPParams {
  phone: string;
}
export interface verifySmsCodeParams {
  userId: string | null;
  token: string | null
}

export interface ResendEmailVerificationParams {
  userId: string;
}

export interface verifyEmailParams {
  userId: string;
  token: string;
}

export interface AccountConfirmationParams {
  email: string | null;
  hash: string | null;
}
export interface resendSmsCodeParams {
  userId: string | null;
  messageMethod:string
}

export interface userVerificationStatusParams {
  userId: string;
  token: string
}