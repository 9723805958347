import React from 'react'
import StaticContent from '../staticContent'
import { Box, Button } from '@mui/material'
import diamondIcon from "Latest/Images/svg/diamondIcon.svg"
import { useHistory } from 'react-router-dom'
import { useAppSelector } from 'hooks/reduxHooks'

const Null = ({ isPending = false, noteTextShow }: any) => {
    const { push } = useHistory()

    const { userAccountDetails } = useAppSelector(state => state.auth);
    const isFreezAcc = userAccountDetails?.restriction?.accountFreeze

    return (
        <>
            <Box sx={{ display: 'flex', alignItems: "flex-start", flexDirection: { xs: "column", md: "row" }, gap: { xs: "20px", md: "32px" } }}>
                <StaticContent noteTextShow={noteTextShow} />

                {isPending ?
                    <Button variant='contained' color="secondary" sx={{ width: "140px" }} onClick={() => push("/2nd-tier-kyc")}>Resume</Button>
                    :
                    <Button variant='contained' disabled={isFreezAcc} color="secondary" sx={{ width: "140px" }} onClick={() => push("/2nd-tier-kyc")} startIcon={<img src={diamondIcon} />}>upgrade</Button>
                }
            </Box>
        </>
    )
}

export default Null