import { Grid, useMediaQuery, useTheme } from '@mui/material'
import React, { useState } from 'react'
import { CardinfoStyle } from '../style/cardinfoStyle'
import QuickLinks from '../QuickLinks'
import MarketData from '../MarketData'
import BlogView from '../BlogView'
import InvestCard from './InvestCardV2'
import RetirementCard from './RetirementCard'
import SavePlus from './SavePlusV2'
import MahaanaDesk from "../MahaanaDesk"
import TodaysNews from "../TodaysNews"
import { BlogStyle } from '../style/blogstyle'
import UpcomingEvent from "../UpcomingEvent"




const Index = ({ Data }: any) => {

    const theme = useTheme();
    const smScreen = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <>
            <Grid container>

                {smScreen && <Grid item xs={12}>
                    <QuickLinks Data={Data} />
                </Grid>}

                {smScreen && <Grid item xs={12} my={3}>
                    <TodaysNews />
                </Grid>} 

                <Grid item xs={12} md={8} pl={{ md: "20px", xs: 0 }} pr={{ md: "24px", xs: 0 }}>

                    <Grid item xs={12} sx={{ ...CardinfoStyle.itemGridLeft }}>
                        <SavePlus Data={Data} />
                    </Grid>

                    <Grid item xs={12} sx={{ ...CardinfoStyle.itemGridLeft }} mt={3}>
                        <InvestCard />
                    </Grid>

                    <Grid item xs={12} sx={{ ...CardinfoStyle.itemGridLeft }} mt={3}>
                        <RetirementCard />
                    </Grid>
                    <Grid sx={{ ...BlogStyle.MahaanaDeskStyles }} mt={3}>
                        <MahaanaDesk />
                    </Grid> 
                </Grid>

                <Grid item xs={12} md={4}  pr={{ md: "20px", xs: 0 }}>
                    {!smScreen && <Grid item xs={12}>
                        <QuickLinks Data={Data} />
                    </Grid>}

                    {!smScreen && <Grid item xs={12} mt={3}>
                        <TodaysNews />
                    </Grid>} 

                    <Grid item xs={12} mt={3}>
                        <MarketData />
                    </Grid>

                    {/* <Grid item xs={12} mt={5}>
                        <BlogView />
                    </Grid> */}

                    <Grid item xs={12} my={3}>
                        <UpcomingEvent />
                    </Grid> 
                </Grid>


            </Grid>
        </ >
    )
}

export default Index