import { Box, Button, CircularProgress, Grid, Skeleton, Typography, useMediaQuery, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
//import settingCircleIcon from "Latest/Images/svg/settingCircleIcon.svg"
import settingCircleIcon from "Latest/Images/svg/settingCircleIconV2.svg"
import LoadingBtnCustom from 'Latest/ComponentV1/LoadingBtnCustom'
import { useAppLoading } from 'hooks/appLoading'
import LoadingBtnCustomBack from 'Latest/ComponentV1/LoadingBtnCustomBack'
import { useHistory } from 'react-router-dom'


const GenerateRaastError = ({ previousStep }: any) => {

    const theme = useTheme();
    const smScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const { push } = useHistory();

    const loading = useAppLoading('onGenerateRaastId');

  

    const buttonHtml = () => {
        return (
            <>
                <LoadingBtnCustomBack handleOnClick={previousStep ? previousStep : () => push("/")} btnTitle={"Back"} />
                {/*
                <LoadingBtnCustom handleOnClick={handleNext} btnTitle={"Generate IBAN"} loading={loading} fullWidth={smScreen} checkFreezAcc={true}/>
                */}
            </>
        )
    }

    return (
        <>
        
            <Box sx={{ pt: { xs: "8px", md: "8px" } }}>
                
                <Box sx={{ display: "flex", justifyContent: "center" }} >
                    <img src={settingCircleIcon} />
                </Box>

                <Typography variant="h5" color="rgba(130, 27, 19, 1)" textAlign="center" sx={{ fontWeight: "500", mt: "16px", mb:"7px" }}>We couldn’t generate your Raast IBAN</Typography>
                {/*
                <Typography textAlign={"center"} sx={{ pt: "4px", fontSize: "14px!important", color: "rgba(29, 41, 57, 0.70)", fontWeight: 400, maxWidth: "519px", margin: "auto" }}>
                    Your Raast Investment IBAN is automatically generated by the <a href="https://www.cdcpakistan.com/"
                        style={{ textDecorationLine: "underline", color: "#422E84" }} target="_blank" rel="noreferrer"
                    >Central Depository Company (CDC)</a>. All funds sent to the IBAN are maintained with CDC for safe-keeping.
                </Typography>
                */}
                <Typography  variant="body2" color="#1D2939B2" textAlign="center" sx={{ pt: "4px",lineHeight:"24px",fontWeight: 400,fontSize: "16px!important", maxWidth: "525px", margin: "auto"  }}>
                    We're unable to generate your Raast IBAN at the moment. Please try again later. To make a deposit we recommend using the “Bank Deposit” method for now.
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    {buttonHtml()}
                </Box>


            </Box>

                   


        </>
    )
}

export default GenerateRaastError;