import { Box, Button, CircularProgress, Grid, Skeleton, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { PayNowStylesV1 } from '../style/indexV1'
import bankDepositIcon from "Latest/Images/svg/bankDepositIcon.svg"
import { useHistory } from 'react-router-dom'
import { CASH_FUND_ID, FlowPath } from '../../../constants';
import Big from 'big.js'
import { onPayMobIframeLink } from 'redux/reducers/paymentGateways'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { useAppLoading } from 'hooks/appLoading'
import NoAccountImg from "Latest/Images/svg/NoAccount.svg"
import { CurrencyFormatterWithDecimal } from 'utils/calculation'
import CustomSingleCheckBoxV1 from 'jsx/components/Shared/V1/CustomSingleCheckBoxV1'
import { useFormik } from 'formik'
import * as Yup from 'yup';
import LoadingBtnCustomBack from 'Latest/ComponentV1/LoadingBtnCustomBack'
import { payMobServiceFeeActions } from 'redux/actions'
import warningIcon from 'Latest/Images/svg/WarningDiamond.svg';

const PayMobCard = ({ categoryAcc, formik, goalId, originalAmt, previousStep }: any) => {


    const dispatch = useAppDispatch();
    const { goalFlowId, goalFlowPath, goalIdPost ,} = useAppSelector(state => state?.portfolio);

    const loading = useAppLoading('paymentGateway');
    const [iframeLoading, setIframeLoading] = useState(false)
    const [payMobLink, setPayMobLink] = useState<any>("")
    const { location, push } = useHistory();
    const loc: any = location.state

    const { addGoalBody } = useAppSelector(state => state.sharedData);
    

    

    // const amt = goalId ? (goalId === CASH_FUND_ID ? (addGoalBody.initialAmount) : formik?.values?.initial_amount) : loc?.amount?.investAmount
    // const AccountCategory = Number(goalFlowId) == CASH_FUND_ID ? FlowPath.Cashfund : FlowPath.Invest

    const totalAmt = Number(payMobLink?.transactionFee) + originalAmt
    const transactionFee = CurrencyFormatterWithDecimal(payMobLink?.transactionFee, true, "never", false)
    useEffect(()=>{
        dispatch(payMobServiceFeeActions(Number(payMobLink?.transactionFee)))
    },[payMobLink?.transactionFee])

    const amtCardArr = [
        { title: "Deposit Amount", amt: CurrencyFormatterWithDecimal(Number(originalAmt) || 0, true, "never", false) },
        { title: "Transaction Fee (2.2% + Tax)", amt: transactionFee },
        // { title: "Total Amount", amt: CurrencyFormatterWithDecimal(totalAmt || 0, true, "never", false) },
    ]

    const handlePostApi = () => {
        const amountInPaisa = Big(originalAmt).mul(100)
        const data = {
            "amountCents": `${amountInPaisa}`,
            "currency": "pkr",
            "goalId": goalIdPost,
            "accountCategory": categoryAcc,
        }

        const onSuccess = (message: any, response: any) => {
            setPayMobLink(message)
            setIframeLoading(true)
        }
        const onFail = (message: any, response: any) => {
            console.log(message, response.status, "OPOOP")
            setPayMobLink("fail")
        }

        dispatch(onPayMobIframeLink(data, onSuccess, onFail))
    }

    const formikTerms = useFormik({
        enableReinitialize: true,
        initialValues: { term1: false },
        onSubmit: values => {
            console.log(values, "POIUY")
        },
        validationSchema: Yup.object().shape({
            term1: Yup.bool().oneOf([true], 'Please read and agree to our terms & conditions.')
        })
    });

    useEffect(() => {
        handlePostApi()
    }, [])

    useEffect(() => {
        if (formikTerms?.values?.term1) {
            handlePostApi()
        }
    }, [formikTerms?.values?.term1])


    const buttonHtml = () => {
        return (
            <>
                {<LoadingBtnCustomBack handleOnClick={previousStep ? previousStep : ()=>push("/")} btnTitle={"Back"} />}
            </>
        )
    }
    interface ImportantInfoProps {
        data: string[];
    }
    const ImportantInfoComp = ({ data }: ImportantInfoProps) => {
        return (
          <Box sx={{ borderBottom: "1px solid #D0D5DD40", borderRadius: "8px", pt: '12px', pb: '8px', backgroundColor: "#FEE9E8", marginTop: "25px" }}>
            <Box sx={{ px: '1rem', display: "flex", alignItems: 'center', gap: '4px', mb: '8px' }}>
              <img src={warningIcon} height={20} width={20} alt="Warning" />
              <Typography variant='h6' color="#821B13">Important information</Typography>
            </Box>
            <Box px={5}>
              {data?.map((info: string) => (
                <Typography key={info} sx={{ listStyleType: 'disc', display: 'list-item' }} variant='body2' color={"#101828D9"} dangerouslySetInnerHTML={{ __html: info }} />
              ))}
            </Box>
          </Box>
        );
      };

    return (
        <>
         <ImportantInfoComp data={[
        `Please only transfer funds from a bank account that’s in <span style="color:#821B13; font-weight:600">YOUR NAME.</span>`,
        "If you transfer from a bank account not in your name, your deposit will be reverted in 1 month.",
        "You must submit proof of payment. Your transaction will not be processed otherwise."
        ]}/>
            <Box sx={{ padding: { xs: "16px 0px", md: "16px 16px 0px 16px" } }}>
                <Typography variant='h4' color={"#422E84"}>Transaction details </Typography>

                <Box sx={{
                    py: "16px"
                    , display: "flex", gap: "12px", flexDirection: "column"
                }}>


                    {amtCardArr.map((item: any, index: number) => {
                        return (
                            <Box key={index} sx={{ display: "flex", justifyContent: "space-between", gap: "12px" }} >
                                <Typography variant='body1' color={"#101828"}>{item.title}</Typography>
                                <Typography variant='h5' color={"#475467"}>
                                    {loading ? <Skeleton variant="text" width={60} /> : item.amt}
                                </Typography>
                            </Box>
                        )
                    })}


                </Box>


                <Box sx={{
                    borderTop: "1px solid rgba(29, 41, 57, 0.70)", display: "flex", justifyContent: "space-between",
                    py: "8px"
                }}>
                    <Typography variant='h5' color={"#422E84"}>Total Amount</Typography>
                    <Typography variant='h1' color={"#422E84"}>
                        {loading ? <Skeleton variant="text" width={60} /> : CurrencyFormatterWithDecimal(totalAmt || 0, true, "never", false)}
                    </Typography>
                </Box>

                <Typography variant='body1' sx={{ color: "#1D2939", padding: "12px 16px" }} textAlign={"center"}><span style={{ fontWeight: 500 }}>Important:</span> Deposit must be made from a card that's in your name</Typography>

                <Box sx={{ mt: "24px" }}>
                    {(loading) ?
                        <CircularIndeterminate />
                        :
                        payMobLink == "fail" ?
                            <RetryComp handlePostApi={handlePostApi} />
                            :
                            <>

                                {iframeLoading && <CircularIndeterminate />}

                                <Box sx={{ position: "relative", height: { xs: "860px", sm: "830px", md: "850px", lg: "750px" }, mx: { xs: "-22px", md: "0px" }, display: iframeLoading ? "none" : "" }}>

                                    <iframe
                                        src={payMobLink?.iframe}
                                        style={{
                                            // filter: formikTerms?.values?.term1 ? "none" : "grayscale(1)",
                                            width: "100%",
                                            height: '100%',
                                            borderColor: "transparent",
                                            position: "relative",
                                            // zIndex: formikTerms?.values?.term1 ? 10 : 0
                                        }}
                                        onLoad={() => setIframeLoading(false)}
                                    />

                                </Box>


                            </>
                    }
                </Box>


                {<Box sx={{ mt: { xs: "24px", md: "40px" }, display: "flex", justifyContent: "center", gap: "8px" }}>
                    {buttonHtml()}
                </Box>}

            </Box>





            {/* <Box sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>

                <Grid item xs={12} sx={{ display: "flex", gap: "26px", pl: 1 }}>
                    <CustomSingleCheckBoxV1
                        formik={formikTerms}
                        fieldName={`term1`}
                        label={`I have read and agree to Mahaana’s <span style=color:#43BED8 ; cursor:pointer>terms and conditions.</span>`}
                    />
                </Grid>


                <Box sx={PayNowStylesV1.cardBox}>

                    <Typography className='mainCardHead' variant='h6' > <img src={bankDepositIcon} />Pay With Card</Typography>

                    <Box className="innerMainBox">

                        <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: 'center' }}>
                            <Box sx={{ borderRadius: "6px", border: "1px solid #EAECF0", width: { xs: "100%", md: "500px", } }}>
                                {amtCardArr.map((item: any, index: number) => {
                                    return (
                                        <Box key={index} sx={{ p: "9px 16px", background: index == 1 ? "#F9FAFB" : "", display: "flex", justifyContent: "space-between" }} >
                                            <Typography variant='body2' color={"#101828"}>{item.title}</Typography>
                                            <Typography variant='body2' color={"#475467"}>
                                                {loading ? <Skeleton variant="text" width={60} /> : item.amt}
                                            </Typography>
                                        </Box>
                                    )
                                }
                                )}
                            </Box>
                            {!formikTerms?.values?.term1 && <Typography variant='body2' sx={{ color: "#1D2939", p: "8px 12px", borderRadius: "4px", background: "#F5FAFF", mt: "10px" }}>
                                Please accept the terms and conditions to continue to payment
                            </Typography>}


                        </Box>

                        <Box sx={{ mt: "24px" }}>
                            {(loading) ?
                                <CircularIndeterminate />
                                :
                                payMobLink == "fail" ?
                                    <RetryComp handlePostApi={handlePostApi} />
                                    :
                                    <>

                                        {iframeLoading && <CircularIndeterminate />}

                                        <Box sx={{ position: "relative", height: { xs: "860px", sm: "830px", md: "850px", lg: "750px" }, mx: { xs: "-22px", md: "0px" }, display: iframeLoading ? "none" : "" }}>

                                            <iframe
                                                src={payMobLink?.iframe}
                                                style={{
                                                    // filter: formikTerms?.values?.term1 ? "none" : "grayscale(1)",
                                                    width: "100%",
                                                    height: '100%',
                                                    borderColor: "transparent",
                                                    position: "relative",
                                                    // zIndex: formikTerms?.values?.term1 ? 10 : 0
                                                }}
                                                onLoad={() => setIframeLoading(false)}
                                            />
                                            <Box sx={{
                                                position: "absolute",
                                                top: 0,
                                                zIndex: formikTerms?.values?.term1 ? 0 : 10,
                                                width: "100%",
                                                height: "100%",

                                            }}></Box>

                                        </Box>


                                    </>
                            }
                        </Box>
                    </Box>
                </Box>
            </Box> */}
        </>
    )
}

export default PayMobCard

const CircularIndeterminate = () => {
    return (
        <Box sx={{ display: 'flex', justifyContent: "center", flexDirection: "column", alignItems: "center", gap: 3, mt: 4 }}>
            <CircularProgress color='secondary' size={90} />
            <Typography>Please wait for a while...</Typography>
        </Box>
    )
}

const RetryComp = ({ handlePostApi }: any) => {
    return (
        <Box sx={{ display: 'flex', justifyContent: "center", flexDirection: "column", alignItems: "center", gap: 1, mt: 4 }}>
            <img src={NoAccountImg} />
            <Typography>Something went wrong...</Typography>
            <Button variant='contained' color="secondary" onClick={handlePostApi}>Try again</Button>
        </Box>
    )
}
