import { Box, Typography } from '@mui/material'
import { useAppSelector } from 'hooks/reduxHooks'
import React from 'react'

const BankDetailCard = ({ iban, userBankList }: props) => {

    const { banksList } = useAppSelector((state: any) => state.sharedData)

    const filterBank = userBankList.filter((el: BankDetailType) => el.accountNumber == iban)
    const userBank = filterBank?.length > 0 ? filterBank[0] : userBankList[0]
    const icon = banksList?.find((x: any) => x.label === userBank?.bankName)?.image;

    return (
        <Box sx={{ display: "flex" }}>
            <Box sx={{ borderRadius: "8px", background: "#F9FAFB", display: "flex", alignItems: 'center', p: "10px 14px", flexDirection: "row", gap: 1 }}>
                <img src={icon} height={55} width={55} alt='bank logo image' />
                <Box>
                    {/* <Typography color={"rgba(29, 41, 57, 0.70)"} fontSize={"14px"}>{userBank?.accountTitle || "-"}</Typography> */}
                    <Typography color={"#1D2939"} fontSize={"16px"} fontWeight={500}>{userBank?.bankName || "-"}</Typography>
                    <Typography color={"#1D2939"} fontSize={"16px"} fontWeight={500}>{userBank?.accountNumber || "-"}</Typography>
                </Box>
            </Box>
        </Box>
    )
}


export default BankDetailCard

interface props {
    iban: string;
    userBankList: BankDetailType[];
}

type BankDetailType = {
    accountNumber: string;
    accountTitle: string;
    bankName: string;
    created?: string
}
