/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Button, Container, Grid, Typography } from '@mui/material';
import { memo } from 'react';
import UploadField from 'jsx/components/Shared/V2/FileUploadFieldV2';
import CustomSingleCheckBox from 'jsx/components/Shared/CustomSingleCheckBox';
import { cnicViewStyle } from "Latest/MyProfileV1/Style/style";
import LoadingBtnCustom from 'Latest/ComponentV1/LoadingBtnCustom';

const NationalIdentityDetails = ({ formik, handleClose, inputFiles, loading  }: any) => {
     
    return (
        <Container disableGutters maxWidth={"lg"} sx={{ ml: 0, px: 0 }}>

            <Grid container spacing={3} >

                <Grid item xs={12} sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap", gap: "16px" }}>
                    <Typography variant='h4' sx={{ ...cnicViewStyle.title }}>Identity Card</Typography>
                    <LoadingBtnCustom style={{ ...cnicViewStyle.approvalBtn }} checkFreezAcc={true} handleOnClick={() => formik.submitForm()} loading={loading} btnTitle={"Submit for Approval"} />
                </Grid>

                <Grid item md={6} xs={12} sm={6} >
                    <UploadField
                        required
                        acceptedType='image/png, image/jpeg'
                        buttonTitle="Upload front view of CNIC"
                        col={12}
                        fieldName="UserNICFront"
                        formik={formik}
                        inputFiles={inputFiles}
                        maxFiles={1}
                        title={"FRONT VIEW"}
                        style={{ height: { xs: "220px", md: "262px" }, '& svg ~ p': { fontSize: "14px", fontWeight: 500, textAlign: "center" } }}
                        subTitle="Ensure picture quality is clear"
                    />
                </Grid>

                <Grid item md={6} xs={12} sm={6} >
                    <UploadField
                        required
                        acceptedType='image/png, image/jpeg'
                        buttonTitle="Upload back view of CNIC"
                        col={12}
                        fieldName="UserNICBack"
                        formik={formik}
                        inputFiles={inputFiles}
                        maxFiles={1}
                        title={"BACK VIEW"}
                        style={{ height: { xs: "220px", md: "262px" }, '& svg ~ p': { fontSize: "14px", fontWeight: 500, textAlign: "center" } }}
                        subTitle="Ensure picture quality is clear"
                    />
                </Grid>

                <Grid item md={12} xs={12} sx={cnicViewStyle.checkBoxWrapper}>
                    <CustomSingleCheckBox
                        formik={formik}
                        fieldName={`consentCheck`}
                        label={`I give consent to Mahaana to process and verify this CNIC for account opening through NADRA`}
                        type={"checkbox"}
                        styleErr={{ marginLeft: "0px" }}
                    />
                </Grid>
            </Grid>
        </Container >
    );
};

export default memo(NationalIdentityDetails);

interface CnicProps {
    backNICFile: any;
    formik: any;
    frontNICFile: any;
    setUserNICFront: any;
    setUserNICBack: any;
    imageError: boolean;
    setImageError: (isImage: boolean) => void;
}
