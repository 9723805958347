import { Alert, Box, Container, Grid, Typography, Button, Divider, Modal, useTheme, Drawer, useMediaQuery, AlertTitle } from '@mui/material'
import React, { useEffect, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { CurrencyFormatter, CurrencyFormatterWithDecimal } from 'utils/calculation';
import Flag from "../../Latest/Images/flag.png"
import { investStyle } from './style';
import * as Yup from 'yup';
import { ErrorMessage, useFormik } from 'formik';
import InputField from 'jsx/components/Shared/V1/FormikInputFieldLatestV1';
// import BankCheckBoxCard from '../../Component/bankCheckBoxCard';
import BankDetails from 'Latest/MyProfile/BankDetails/bankDetailGrid';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'hooks/reduxHooks';
import { PostRedemption } from 'redux/reducers/payment';
import { ModalStyle } from 'Latest/MyProfile/Style/modalStyle';
import ThankYouModal from 'Latest/MyProfile/Modal/thankYouModal';
import ErrModal from 'Latest/MyProfile/Modal/errModal';
import PayReceipt from 'Latest/PayReceipt';
import SuccessReceipt from 'Latest/PayReceipt/successReceiptOld';
import CustomModal from "Latest/MyProfile/ModalV2/confirmationModal";
import SaveIcon from "Latest/Images/svg/warningIcon.svg"
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { tokens } from 'theme';
import useStyles from 'Latest/ComponentV1/CustomMakeStyle/CustomButtonNeutral';
import { useAppLoading } from 'hooks/appLoading';
import { useHistory } from 'react-router-dom';
import { links } from 'static/links';
import RenderItem from './renderItem';
import { IbanFormater } from 'utils/validator';
import SuccessReceiptModal from './successReceiptModal';
import arrowRed from "Latest/Images/svg/arrowRed.png"
import BankCheckBoxCard from 'Latest/DashboardScreen/GoalDashboard/Component/bankCheckBoxCard';
import FormikConfirmPasswordField from 'jsx/components/MUI_components/FormikConfirmPasswordField';
import { onGetUserBankAccount } from 'redux/reducers/sharedStateData';
import FormikReactSelect from "jsx/components/Shared/V1/FormikReactSelectV1"
import ButtonRowV1 from 'Latest/ComponentV1/ButtonRowV1';
import { DashboardApisCaller, KycStatus } from '../../constants';
import WithdrawalOTPValidation from 'jsx/pages/WithdrawalOTP-Validation';
import useStylesWarningAlert from 'Latest/ComponentV1/CustomMakeStyle/customWarningAlert';

type Anchor = 'top' | 'left' | 'bottom' | 'right';
type ErrorMessage = string | JSX.Element;
type CustomValidationError = {
    message: any;
};


const Withdraw = ({ Data, anchor = "right", item, }: any) => {

    const { location, push } = useHistory();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const { userAccountDetails } = useAppSelector(state => state.auth);
    const smScreen = useMediaQuery(theme.breakpoints.up('sm'));
    const { userBankAcc } = useAppSelector(state => state.sharedData)
    const [selectionProducts, setSelectionProducts] = React.useState([])
    const [otpData, setOtpData] = React.useState<string>();
    const { onCallApis } = DashboardApisCaller();
    const comment: any = userAccountDetails?.kyc?.comment
    const classess = useStylesWarningAlert()


    useEffect(() => {
        dispatch(onGetUserBankAccount())
    }, [])

    const handleClose = () => {
        if (openThankModal) {
            window.location.reload();
        }
        setOpen(false)
    };

    const loading = useAppLoading('payment');

    const [openThankModal, setOpenThankModal] = useState(false);
    const [openErrModal, setOpenErrModal] = useState(false);
    const [openCustomModal, setOpenCustomModal] = useState(false);

    const dispatch = useDispatch();
    const { dashboardDetail } = useAppSelector((state: any) => state.dashboard);
    const { user } = useAppSelector(state => state.auth);

    const redemptionLimit = Number(Data?.potDetails?.portfolioAmount)
    const cashFundInvestmentId = Data?.idGoal


    const successOtpModal = (success: boolean) => {
        setOpenThankModal(success)
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: { withDrawAmount: '', selectedProduct: "", selectedProductName: "" },
        onSubmit: values => {
            handleConfirm()
        },
        validationSchema: Yup.object({
            withDrawAmount: Yup.number()
                .required("Amount is required").nullable()
                .min(500, "Amount should be greater than or equal to PKR 500.00")
                .test('require', '', function (value, context): any {
                    let errorMessage: ErrorMessage = '';
                    if (value) {
                        const found = dashboardDetail?.goals.find((goal: any) => goal.idGoal == formik?.values?.selectedProduct);
                        if (found && value > Number(found?.potDetails?.portfolioAmount)) {
                            return this.createError({ message: `Your redemption limit is ${CurrencyFormatterWithDecimal(found?.potDetails?.portfolioAmount, true, "never", false)}` })
                        }

                        if (userAccountDetails?.kyc?.status != 'ApprovalSucceeded') {
                            if (value > 400000) {
                                if (userAccountDetails?.kyc?.status === KycStatus.ApprovalHalted) {
                                    errorMessage = handleFatcaErrorBox()
                                } else {
                                    errorMessage = handleErrorBox();
                                }

                            }
                        }
                    }

                    const customError: CustomValidationError = {
                        message: errorMessage,
                    };

                    if (customError.message == "") {
                        return true
                    } else {
                        // Explicitly cast the error object to the CustomValidationError type
                        return (this.createError as (params: CustomValidationError) => any)(customError);
                    }
                })
            // .max(redemptionLimit&& perGoalRedemtion, `Your redemption limit is ${CurrencyFormatterWithDecimal(perGoalRedemtion, true, "never", false)} `)
            ,
            selectedProduct: Yup.string().required("Product is required").nullable(),
        })
    })

    React.useEffect(() => {
        if (dashboardDetail?.goals.length > 0) {
            const filteredGoals = dashboardDetail?.goals?.filter((item: any) => item?.goalStatus != "Abandon")
            const filterKey = filteredGoals?.map((item: any, ind: number) => (
                {
                    "category": item.category,
                    "name": item.name,
                    "label": item.category == "CashFund" ? "Save+" : `Invest: ${item.name}`,
                    // "label": item.name,
                    "value": item.idGoal
                }))
            setSelectionProducts(filterKey)
        }
    }, [dashboardDetail])

    const returnCategory = () => {
        const category: any = selectionProducts.filter((item: any) => item.value == formik?.values?.selectedProduct);
        return category
    }

    const returnBrokerType = () => {
        const brokerType: any = dashboardDetail?.goals.filter((item: any) => item.idGoal == formik?.values?.selectedProduct);
        return brokerType
    }

    const handleDispatchAPI = () => {
        const filterCat = returnCategory();
        const filterVal = returnBrokerType();


        const body = {
            redemptionAmount: formik?.values?.withDrawAmount,
            id: formik?.values?.selectedProduct,
            accountCategory: filterCat[0].category
        }

        const param = {
            id: user?.id,
            category: filterVal[0]?.brokerType === "JsGlobal" ? "gigoals" : filterCat[0].category
        }
        const onSuccess = (message: string, response: any) => {
            setOtpData(message)
            setOpenCustomModal(true)
            setOpenErrModal(false)
            console.log(message, response)
            // setOpenThankModal(true)
            // formik.resetForm()
            // setOpenCustomModal(false)
            // setState({ ...state, [anchor]: false });  //drawer close
        }
        const onFail = (message: string, response: any) => {
            console.log(message, response.status, "OPOOP", response)
            setOpenErrModal(true)
            setOpenCustomModal(false)
        }
        dispatch(PostRedemption(body, param, onSuccess, onFail))
    }

    const handleSelectedBank = (item: any) => {
        console.log(item)
    }

    const handleErrorBox = () => {
        return (
            <Box sx={{ mt: "4px" }}>
                <Alert severity="error" color='error' sx={{ border: "1px solid #FDA29B" }}>
                    <Box>
                        <Typography variant='body2' color="#7A271A" fontWeight={"600"}>Withdrawal limit exceeded</Typography>

                        <Typography variant='body2' color="#7A271A">You can only make a withdrawal of <strong>PKR 400,000.00.</strong> Upgrade your account to remove limits.</Typography>
                        <Button disableElevation size="small" variant='contained' color="primary" sx={{ mt: 1 }} onClick={() => push("/myprofile?scroll=true")}>Update Now</Button>
                    </Box>
                </Alert>
            </Box>
        )
    }

    const handleFatcaErrorBox = () => {
        return (
            <Box sx={{ mt: "12px" , width:"100%" }}>
                <Alert severity="warning" className={classess.customAlert} >
                    <AlertTitle className={classess.customAlertTitle}>Some documents missing in your 2nd tier KYC </AlertTitle>
                    {comment != null && <Typography className={classess.customText}>{comment}</Typography>}
                    <Button
                        sx={{
                            borderRadius: "100px", height: "37px", textTransform: "unset", fontWeight: 500,
                            padding: "8px 16px", border: "1px solid #541313",
                            backgroundColor: "#fff", color: "#541313",
                            fontSize: "14px", lineHeight: "21px", mt: 1
                        }}
                        onClick={() => push("/myprofile?scroll=true")}
                    >
                        Update Now
                    </Button>
                </Alert>
            </Box>
        )
    }

    const ContentSuccess = () => {
        return (
            <Grid item xs={12} sx={{ pt: "8px" }}>
                <Typography sx={ModalStyle.headStyle}>Redemption request send successfully!</Typography>
                <Typography sx={ModalStyle.subheadStyle}>Thank you for requesting redemption. Your redemption amount is in process it may take sometime.</Typography>
            </Grid>
        )
    }

    const ContentFailed = () => {
        return (
            <Grid item xs={12} sx={{ pt: "8px" }}>
                <Typography sx={ModalStyle.headStyle}>Oops!</Typography>
                <Typography sx={ModalStyle.subheadStyle}>The amount requested for redemption is either incorrect or does not meet the minimum required amount.</Typography>
            </Grid>
        )
    }

    const confirmationContent = () => {
        return (
            <Typography variant='h6' color={"#422E84"} pb="10px">Are you sure you wish to withdraw?</Typography>
        )
    }

    const handleConfirm = () => {
        handleDispatchAPI()
    }

    const resendWithdrawalOTP = () => {
        handleConfirm()
    }

    const handleClickSuccessModal = (event: React.KeyboardEvent | React.MouseEvent) => {
        onCallApis();
        setOpenThankModal(false);
        formik.resetForm();
        setOpenCustomModal(false);
        toggleDrawer(anchor, false)(event)

    }
    const closeHandler = (event: React.KeyboardEvent | React.MouseEvent) => {
        onCallApis();
        setOpenThankModal(false);
        formik.resetForm();
        setOpenCustomModal(false);
        toggleDrawer(anchor, false)(event)

    }

    const [state, setState] = React.useState<any>({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const toggleDrawer =
        (anchor: Anchor, open: boolean) =>
            (event: React.KeyboardEvent | React.MouseEvent) => {
                if (
                    event?.type === 'keydown' &&
                    ((event as React.KeyboardEvent).key === 'Tab' ||
                        (event as React.KeyboardEvent).key === 'Shift')
                ) {
                    return;
                }
                setState({ ...state, [anchor]: open });
            };

    const filterWithdrawAcc = userBankAcc && userBankAcc.filter((x: any) => (x.accountNumber == userAccountDetails?.iban))

    const selectedBank = () => {
        console.log("")
    }

    const handleCloseDrawer = (event: React.KeyboardEvent | React.MouseEvent) => {
        toggleDrawer(anchor, false)(event)
        formik.resetForm()
        setOpenCustomModal(false)
    }

    return (
        <>
            <RenderItem handleOpen={toggleDrawer(anchor, true)} item={item} />

            <SuccessReceiptModal
                handleOpen={() => setOpenThankModal(true)} open={openThankModal} handleClose={closeHandler}
                amount={formik?.values?.withDrawAmount}
                productName={formik?.values?.selectedProductName}
                customhandleBtn={handleClickSuccessModal}
            />

            <Drawer
                anchor={anchor}
                open={state[anchor]}
                onClose={handleCloseDrawer}
                sx={{ zIndex: "1201" }}
                PaperProps={{
                    sx: { width: { xs: "100%", sm: "80%", md: "448px" }, borderRadius: "8px 0 8px 0" },
                }}
            >
                <Box
                    role="presentation"
                // onClick={toggleDrawer(anchor, false)}
                // onKeyDown={toggleDrawer(anchor, false)}
                >

                    <Container disableGutters sx={{ px: "24px" }}>

                        {/* <ThankYouModal handleOpen={() => setOpenThankModal(true)} open={openThankModal} handleClose={() => setOpenThankModal(false)} Content={ContentSuccess} /> */}
                        <ErrModal handleOpen={() => setOpenErrModal(true)} open={openErrModal} handleClose={() => setOpenErrModal(false)} Content={ContentFailed} />
                        {/*<CustomModal
                            showIcon={false} btnTitle={"Yes"} handleOpen={() => setOpenCustomModal(true)} open={openCustomModal}
                            handleClose={() => setOpenCustomModal(false)} handleConfirm={handleConfirm} Content={confirmationContent}
                            loadingConfirmBtn={loading} styleBox={{ alignItems: "center" }}
                        /> */}

                        <Box sx={{ position: "absolute", right: 12, top: 20 }}>
                            <IconButton aria-label="upload picture" component="label" onClick={handleCloseDrawer}>
                                <CloseIcon />
                            </IconButton>
                        </Box>

                        <Grid container >

                            <Grid item xs={12} >

                                <Box sx={{ py: "24px", display: "flex", alignItems: "center", gap: "20px" }}>
                                    <img src={arrowRed} />
                                    <Typography variant='h4' color={"#344054"}>Withdraw money</Typography>
                                </Box>
                                {/* <Box sx={{ display: "flex", alignItems: "flex-start", gap: "20px" }}>
                                    <img src={arrowRed} />

                                    <Box>
                                        <Typography variant='h4' color={"#344054"}>Withdraw money</Typography>
                                        {
                                            !openCustomModal && <Typography variant='body2' sx={{ mt: "6px" }}>Total holdings <Typography component={"span"} variant='body2' sx={{ borderRadius: "16px", background: "#F9F5FF", p: "2px 10px", color: "#6941C6" }}>{CurrencyFormatterWithDecimal(redemptionLimit, true, "never", false)}</Typography>

                                            </Typography>
                                        }


                                    </Box>
                                </Box> */}



                            </Grid>

                            {
                                openCustomModal ? (
                                    <WithdrawalOTPValidation handleResendWithdrawalOTP={resendWithdrawalOTP} otpData={otpData} loading={loading} category={returnCategory} successModal={successOtpModal} />
                                ) : (
                                    <>
                                        <Grid item xs={12} >
                                            <FormikReactSelect
                                                required
                                                disabled={false}
                                                fieldName='selectedProduct'
                                                fields={selectionProducts}
                                                formik={formik}
                                                label='Select product'
                                                onChange={(val, option) => {
                                                    formik.setFieldValue('selectedProductName', option.name);
                                                    formik.setFieldValue('selectedProduct', option.value);
                                                    // formik.setFieldValue('selectedProductCat', option.category);
                                                }}
                                                placeholder='Select'
                                            />
                                        </Grid>

                                        {/* it will be uncommit when bank is added */}
                                        {/* <Grid item xs={12}  >
                    <BankDetails screen="dashboard" selectedBank={handleSelectedBank} />
                </Grid> */}

                                        {/* <Grid item xs={12} sx={{ mt: "24px", py: "20px", display: "flex", flexDirection: "column", flexWrap: "wrap", gap: "8px" }}  >
                                        <Box >
                                            <Typography textAlign={"center"} variant='h5' sx={{ color: "#E62E2E" }}>
                                                {formik.values.withDrawAmount == '' ?
                                                    "PKR 0.00"
                                                    :
                                                    CurrencyFormatter(Number(formik.values.withDrawAmount) || 0, true)
                                                }
                                            </Typography>
                                        </Box>

                                        <Box >
                                            <Typography textAlign={"center"} variant='body1'>Transaction amount</Typography>
                                        </Box>
                                    </Grid> */}

                                        {/* <Grid item xs={12} sx={{ mt: "24px", }} >
                                <BankCheckBoxCard list={filterWithdrawAcc} deleteBtn={false} selectedBank={selectedBank} defaultViewLimit={1} isAccTitleShow={false} />
                            </Grid> */}

                                        <Grid item xs={12} sx={{ mt: "24px", }}>
                                            <Box >
                                                <InputField
                                                    // autoFocus={true}
                                                    validateOnType={true}
                                                    formik={formik}
                                                    label={"Withdrawal amount"}
                                                    maxLength={10}
                                                    name={"withDrawAmount"}
                                                    placeholder='Type Amount...'
                                                    thousandSeparator={true}
                                                    type={"number"}
                                                    inputEnd="PKR"
                                                    disabled={!formik.values.selectedProduct}
                                                />
                                            </Box>
                                        </Grid>

                                        <Grid item xs={12} sx={{ mt: "24px", }}>

                                            <Typography sx={{ fontSize: "13px!important" }}>Withdrawal will be made to the bank account submitted during onboarding.</Typography>

                                            <Box sx={{ borderRadius: "8px", border: "1px solid #E4E4E4", background: "#FCFCFD", p: "8px 16px", mt: "6px" }}>

                                                <Typography variant='body2' display='flex'>
                                                    <Typography sx={{ width: "68px", fontWeight: 500 }} >Bank</Typography>
                                                    {userAccountDetails?.bankName || "-"}
                                                </Typography>

                                                <Typography variant='body2' display='flex' mt="2px">
                                                    <Typography sx={{ width: "68px", fontWeight: 500 }}> IBAN</Typography>
                                                    {userAccountDetails?.iban || "-"}
                                                </Typography>

                                            </Box>

                                        </Grid>

                                        <Grid item xs={12} sx={{ display: "flex", gap: "12px", mt: "32px" }}>
                                            {/* <Box sx={{ width: "50%" }}>
                                    <Button fullWidth size={"large"} variant='outlined' className={classes.neutralButton} onClick={handleCloseDrawer}>Cancel</Button>
                                </Box>
                                <Box sx={{ width: "50%" }}>
                                    <Button disableElevation fullWidth size={"large"} variant='contained' onClick={() => formik.submitForm()} color="secondary">Continue</Button>
                                </Box> */}
                                            <ButtonRowV1
                                                handleBack={handleCloseDrawer}
                                                handleNext={() => formik.submitForm()}
                                                btnTitleBack="Cancel"
                                                btnTitleNext="Continue"
                                                loadingNextBtn={loading}
                                                checkFreezAcc={true}
                                            />
                                        </Grid>
                                    </>
                                )
                            }


                        </Grid>

                    </Container>

                </Box>

            </Drawer>

        </>
    )
}

export default Withdraw