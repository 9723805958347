import { Container, Grid, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useFormik } from 'formik';
import React, { useEffect } from 'react'
import FormikRadioField from 'jsx/components/Shared/V1/FormikRadioFieldV1';
import moment from 'moment';
import { useAppSelector } from 'hooks/reduxHooks';
import { onPatchDividend } from 'redux/reducers/sharedStateData';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { onGetAccountSetup } from 'redux/actions';

const Index = () => {

    const theme = useTheme();
    const smScreen = useMediaQuery(theme.breakpoints.up('sm'));
    const dispatch = useDispatch()
    const fieldSet = [
        {
            "label": "Reinvest dividends into Mahaana Save+ (15% tax for filers, 30% for non-filers)",
            "value": 'Reinvest',
        },
        {
            "label": "Send dividends to my bank account (25% tax for filers, 50% for non-filers)",
            "value": "BankDeposit",
        }
    ]

    const { userAccountDetails } = useAppSelector(state => state.auth);


    const filterDividendPayout = userAccountDetails?.alerts && userAccountDetails?.alerts.filter((x: any) => x?.type?.includes('DividendPayout'))

    const DividendDeclaration = filterDividendPayout?.length && filterDividendPayout[0]?.details?.DividendDeclaration

    const date = filterDividendPayout?.length && filterDividendPayout[0]?.details?.EndingDate
    const hideDate = filterDividendPayout[0]?.enable?.toLowerCase()==="false";
   
    const EndingDate = moment(date, 'YYYY-MM-DD').format("Do MMMM YYYY")
    const EndingDateMY = moment(date, 'YYYY-MM-DD').format("MMMM YYYY")

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: { dividendOpt: DividendDeclaration },
        onSubmit: (values: any) => {
            return;
            // eslint-disable-next-line no-console
        },
        // validationSchema: changePasswordSchema,
    });

    const handleDividend = () => {
        const data = { declarationType: formik?.values?.dividendOpt }
        const onSuccess = () => {
            toast.success(`Dividend type saved successfully`);
            dispatch(onGetAccountSetup());
        }
        dispatch(onPatchDividend(data, onSuccess))
    }

    useEffect(() => {
        if (DividendDeclaration !== formik?.values?.dividendOpt) {
            handleDividend()
        }

    }, [formik?.values?.dividendOpt])


    useEffect(() => {
        return () => { handleRemoveParam() };
    }, [])

    const handleRemoveParam = () => {
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has('value')) {

            // setTimeout(() => {
            // Remove the 'scroll' parameter from the URL
            urlParams.delete('value');

            // Construct the new URL without the 'scroll' parameter
            const newUrl = urlParams.toString() ? window.location.pathname + '?' + urlParams.toString() : window.location.pathname;

            // Replace the current URL with the new URL to remove the 'scroll' parameter and '?'
            window.history.replaceState({}, '', newUrl);
            // }, 6000);
        }
    }


    return (
        <Container maxWidth="lg" sx={{ px: "0px!important", ml: 0 }}>
            <Grid container sx={{ background: "#fff", p: { xs: "24px 16px", md: "30px" } }}>

                <Grid item >
                    <Typography variant='h4'>Dividend distribution (Mahaana Save+)</Typography>

                    <Typography variant='body1' sx={{ mt: "8px" }}>{hideDate?"":`Deadline: ${EndingDate}`}</Typography>

                    <Typography variant='body1' sx={{ mt: "16px" }}>
                        Tax is deducted at the time when dividends are distributed and each distribution method has a different tax deduction percentage.
                        Note that tax deduction is only applied on your gains.
                    </Typography>

                    <Typography variant='body1' sx={{ mt: "16px", mb: "12px" }}>Please choose a preferred dividend distribution method:</Typography>

                    <FormikRadioField
                        required
                        singleLine={true}
                        col={12}
                        fieldName={'dividendOpt'}
                        fields={fieldSet}
                        formik={formik}
                        checkFormStyle={{ alignItems: !smScreen ? "flex-start" : "flex-end", }}
                        style={{ ".form-check-label": { mt: "3px" } }}
                        checkFreezAcc={true}
                    />

                </Grid>
            </Grid>
        </Container >
    )
}

export default Index