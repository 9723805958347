/* eslint-disable no-lone-blocks */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable array-callback-return */
/* eslint-disable sort-keys */
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import DynamicQuestionAnswers from 'Latest/2ndTierKyc/steps/Declaration/dynamicQuestion';
import DynamicQuestionAnswersV2 from 'Latest/2ndTierKyc/steps/Declaration/dynamicQuestionV2';
import Grid from '@mui/material/Grid';
import { Container, Divider, Skeleton, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import '../riskProfile.css';
import { onPostUserAnswersRiskProfile } from 'redux/actions';
import { mainContainerV2, smallScreenBtnWrap } from 'Latest/Style/Style';
import CustomModal from "../../../../Latest/MyProfile/Modal/confirmationModal"
import { InputCategoryEnum } from '../../../../constants/index';
import { ModalStyle } from 'Latest/MyProfile/Style/modalStyle';
import SaveIcon from "Latest/Images/saveIcon.png";
import ButtonRowV1 from 'Latest/ComponentV1/ButtonRowV1';
import LoadingBtnCustom from 'Latest/ComponentV1/LoadingBtnCustom';
import { cnicViewStyle } from "Latest/MyProfileV1/Style/style";

const UpdateCashFundProfile = ({ handleAPIOnUpdate, handleNextStep, handlePrevStep, loading, previousStep, profileQuestions, selectedAnswer, setShowUpdateRiskProfile, showNextBtn = true, url }: any) => {

    const { user, userAccountDetails } = useAppSelector(state => state.auth);
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const smScreen = useMediaQuery(theme.breakpoints.down('md'));

    const cashFundProfileFormikValidationSchema: any = {};
    const cashfundProfileFormikInitialValues: any = {};

    // const cashfundProfileValidations_ = (questionAnswer: any) => {
    //     if (questionAnswer?.regex) {
    //         cashFundProfileFormikValidationSchema[questionAnswer?.id] = Yup.number()
    //             .required(`Field is required.`)
    //             .max(100000000, 'Amount should be less than 1 billion').nullable();
    //     } else {
    //         cashFundProfileFormikValidationSchema[questionAnswer?.id] = Yup.string().required(`Field is required.`);
    //     }
    // };

    const cashfundProfileValidations_ = (questions: any) => {
        const validationSchema: any = {};

        if (questions?.questionAnswer?.length > 0) {
            questions.questionAnswer.forEach((item: any) => {
                const questionId = item?.id;
                const nestedQuestionId = `${questionId}-nested`;

                let filterNestedQuestion: any = []

                validationSchema[questionId] = Yup.string().required('Required').nullable();

                validationSchema[nestedQuestionId] = Yup.mixed().when(questionId, {
                    is: (value: any, schema: any) => {
                        filterNestedQuestion = item?.answer?.answerOptions.filter((i: any) => i.value == value);
                        const checkNestedQuestion = filterNestedQuestion[0]?.answer == null;
                        return !checkNestedQuestion;
                    },
                    then: Yup.mixed().test('', "", function (value: any, context) {
                        if (filterNestedQuestion[0]?.answer?.answerFieldType === "CheckBox") {
                            if (value.length < 2) {
                                return this.createError({
                                    message: "Select at least 2 options"
                                });
                            }
                            return true;
                        } else if (filterNestedQuestion[0]?.answer?.answerFieldType === "RadioButton") {
                            if (value == "" || value == undefined) {
                                return this.createError({
                                    message: "Required"
                                });
                            }
                            return true;
                        }
                        return false; // Not a Checkbox, no validation needed
                    }),
                });
            });
        }

        return Yup.object().shape(validationSchema);
    };

    let nestedQuestion_1 = {};

    if (selectedAnswer?.length) {

        selectedAnswer?.map((item: any) => {
            if (item?.answer?.isAttempted) {
                cashfundProfileFormikInitialValues[item?.id] = item?.answer?.answerOptions[0]?.value;
                if (item?.answer?.answerOptions[0]?.answer?.answerOptions?.length > 0) {
                    if (item?.answer?.answerOptions[0]?.answer?.answerFieldType === InputCategoryEnum.RadioButton) {
                        cashfundProfileFormikInitialValues[`${item?.id}-nested`] = item?.answer?.answerOptions[0]?.answer?.answerOptions[0]?.value
                    } else {
                        cashfundProfileFormikInitialValues[`${item?.id}-nested`] = item?.answer?.answerOptions[0]?.answer?.answerOptions.map(
                            (x: any) => {
                                return x?.value;
                            }
                        );
                    }
                }
            } else {
                cashfundProfileFormikInitialValues[item?.id] = '';
            }

        });

        // if (profileQuestions?.questionAnswer?.length) {
        //     profileQuestions?.questionAnswer?.map(
        //         (value: any, index: any) => {
        //             cashfundProfileValidations_(
        //                 profileQuestions?.questionAnswer[index]
        //             );
        //         }
        //     );
        // }
    }

    const formikCashFundAcc = useFormik({
        initialValues: {
            ...cashfundProfileFormikInitialValues
        },
        enableReinitialize: true,
        onSubmit: values => {
            {
                profileQuestions?.questionAnswer?.map(
                    (value: any, index: any) => {
                        const questionId = profileQuestions?.questionAnswer[index]?.id;
                        const questionIdNested = `${profileQuestions?.questionAnswer[index]?.id}-nested`;
                        let body: any = {
                            investorCode: user.id,
                            investorQueriesDto: [
                                {
                                    id: questionId,
                                    answer: {
                                        answerOptions: [
                                            {
                                                id: 0,
                                                value: values[questionId].toString()
                                            }
                                        ]
                                    },
                                    riskProfileCategoryDto: {
                                        id: profileQuestions?.questionAnswer[index]
                                            ?.category?.id
                                    }
                                }
                            ]
                        };

                        if (values[questionIdNested]) {
                            let nestedValues: any = [];
                            if (questionIdNested === '636c51ca1833d0b8ffb404e4-nested') {
                                nestedValues = values[questionIdNested];
                            } else {
                                if (!!values[questionIdNested]) {
                                    if (values[questionIdNested]?.length > 0) {
                                        const nestedArr = Array.isArray(values[questionIdNested]) ? values[questionIdNested] : [`${values[questionIdNested]}`]
                                        nestedValues = nestedArr?.map(
                                            (item: any) => {
                                                return {
                                                    value: item
                                                };
                                            }
                                        );
                                    } else {
                                        nestedValues = values[questionIdNested];
                                    }
                                }
                            }
                            body = {
                                investorCode: user.id,
                                investorQueriesDto: [
                                    {
                                        id: profileQuestions?.questionAnswer[index].id,
                                        answer: {
                                            answerOptions: [
                                                {
                                                    id: 0,
                                                    value: values[questionId].toString(),
                                                    answer: { answerOptions: nestedValues }
                                                }
                                            ]
                                        },
                                        riskProfileCategoryDto: {
                                            id: profileQuestions?.questionAnswer[index]
                                                ?.category?.id
                                        }
                                    }
                                ]
                            };
                        }

                        if (profileQuestions?.questionAnswer?.length - 1 === index) {
                            dispatch(onPostUserAnswersRiskProfile(body));
                            {
                                !userAccountDetails?.restriction?.accountFreeze && toast.success('Saved successfully', {
                                    autoClose: 5000,
                                    closeOnClick: true,
                                    draggable: true,
                                    hideProgressBar: false,
                                    pauseOnHover: true,
                                    position: 'top-right',
                                    progress: undefined
                                });
                            }

                            setTimeout(() => {
                                if (url == "myprofile") {
                                    handleAPIOnUpdate()
                                } else {
                                    handleNextStep(values)
                                }
                            }, 500);

                        } else {
                            dispatch(onPostUserAnswersRiskProfile(body));
                        }
                    }
                );
            }
        },
        validationSchema: cashfundProfileValidations_(profileQuestions),

    });
    const addNestedQuestion = (questionAnswer: any) => {
        questionAnswer?.answer?.answerOptions?.map((item: any, index: number) => {
            const questionId = questionAnswer?.id;
            if (item?.value === formikCashFundAcc?.values[questionId]) {
                if (item?.answer) {
                    nestedQuestion_1 = { item: item?.answer, activeStep: questionId };
                } else {
                    nestedQuestion_1 = {};
                    if (formikCashFundAcc.values[`${questionId}-nested`]) {
                        formikCashFundAcc.setFieldValue(`${questionId}-nested`, undefined);
                    }
                }
            }
        });
    };

    const { personalisedCategory, personalizeScore } = useAppSelector(state => state.sharedData);
    const [category, setCategory] = useState<any>({})

    useEffect(() => {
        checkCategory()
    }, [personalizeScore])

    const checkCategory = () => {
        const findCategory = personalisedCategory.find((item: any) => personalizeScore >= item.scoreFrom && personalizeScore <= item.scoreTo && item.name)
        setCategory(findCategory)
    }
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleUpdate = async () => {
        const errors = await formikCashFundAcc.validateForm();
        if (Object.keys(errors).length === 0) {
            handleOpen()
        } else {
            formikCashFundAcc.handleSubmit()
        }
    }

    const handleConfirm = () => {
        formikCashFundAcc.handleSubmit()
        handleClose()
        setTimeout(() => {
            setShowUpdateRiskProfile(false)
        }, 1000);
    }

    const disabledUpdateBtn = JSON.stringify(cashfundProfileFormikInitialValues) === JSON.stringify(formikCashFundAcc.values)

    const confirmationContent = () => {
        return (
            <Grid item xs={12} sx={{ display: "flex" }}>
                <Box>
                    <Typography sx={ModalStyle.headStyle}>Save changes?</Typography>
                    <Typography sx={ModalStyle.subheadStyle}>Are you sure you want to update?</Typography>
                </Box>
            </Grid>
        )
    }

    const buttonHtml = () => {
        return (
            showNextBtn && <ButtonRowV1 handleNext={() => formikCashFundAcc.handleSubmit()} handleBack={() => handlePrevStep(formikCashFundAcc)} btnTitleNext={"Continue"} />
        )
    }

    return (
        <>
            <CustomModal handleOpen={handleOpen} open={open} handleClose={handleClose} handleConfirm={handleConfirm} Content={confirmationContent} image={SaveIcon} />
            {url == "myprofile" ?

                <Grid item xs={12} md={12}  >
                    <Grid item md={12} xs={12} >
                        <Typography variant='h4' sx={{ ...cnicViewStyle.title }}>My Risk Profile</Typography>
                    </Grid>

                    <Grid item md={12} sx={{ my: "30px" }}>
                        {loading ?
                            loadingSkeleton()
                            :
                            profileQuestions?.questionAnswer?.map(
                                (value: any, index: any) => {
                                    addNestedQuestion(value);
                                    const checkTextBox = value?.answer?.answerFieldType == InputCategoryEnum.TextBox
                                    return (
                                        <Box key={value}>
                                            <Grid item md={checkTextBox ? 6 : 12}>
                                                {
                                                    <DynamicQuestionAnswers
                                                        formik={formikCashFundAcc}
                                                        nestedQuestion={nestedQuestion_1}
                                                        questionAnswers={value}
                                                    />
                                                }
                                            </Grid>
                                            <Divider sx={{ my: "24px", borderColor: "#EAECF0" }} />
                                        </Box>
                                    );
                                }
                            )

                        }

                    </Grid>

                    <LoadingBtnCustom loading={loading} disabled={disabledUpdateBtn} btnTitle={"Update"} handleOnClick={handleUpdate} checkFreezAcc={true} />
                </Grid>
                :
                <>
                    <Container maxWidth={'lg'} disableGutters={smScreen} sx={{ py: { xs: "24px", md: "40px" } }} >

                        <Grid container sx={mainContainerV2}>
                            <Grid item md={12} xs={12} width={"100%"}>
                                <Typography variant='h4'>Your risk profile</Typography>
                                <Typography variant='body1' sx={{ color: "#667085", pt: "6px", lineHeight: "normal", letterSpacing: "unset" }}>This information will determine your recommended investment portfolio.</Typography>
                            </Grid>
                            <Grid item md={12} mb={3} p={2} width={"100%"}>
                                {
                                    loading ?
                                        loadingSkeleton()
                                        :
                                        profileQuestions?.questionAnswer?.map(
                                            (value: any, index: any) => {
                                                addNestedQuestion(value);
                                                return (
                                                    <>
                                                        <Box key={value}>
                                                            {index != 0 && <Box p={2} />}
                                                            {/* {
                                                                <DynamicQuestionAnswers
                                                                    formik={formikCashFundAcc}
                                                                    nestedQuestion={nestedQuestion_1}
                                                                    questionAnswers={value}
                                                                />
                                                            } */}
                                                            {
                                                                <DynamicQuestionAnswersV2
                                                                    formik={formikCashFundAcc}
                                                                    nestedQuestion={nestedQuestion_1}
                                                                    questionAnswers={value}
                                                                />
                                                            }
                                                            {/* <Divider sx={{ my: "24px", borderColor: "#EAECF0" }} /> */}
                                                        </Box>

                                                    </>
                                                );
                                            }
                                        )}

                            </Grid>

                            {!smScreen && <Box width={"100%"} sx={{ mt: { xs: "24px", md: "16px" } }}>
                                {buttonHtml()}
                            </Box>}

                        </Grid>

                        {smScreen && <Box sx={{ ...smallScreenBtnWrap, mt: "24px" }}>
                            {buttonHtml()}
                        </Box>}

                    </Container>


                </>
            }
        </>
    );
};

export default UpdateCashFundProfile;

const loadingSkeleton = () => {
    return (
        <>
            <Stack spacing={2}>
                {[1, 2, 3].map((i) => {
                    return (
                        <Skeleton key={i} sx={{ width: { sm: "100%", md: "50%" } }} height={25} />
                    )
                })}
            </Stack>
        </>
    )
}
