import React from 'react'
import StaticContent from '../staticContent'
import { Alert, AlertTitle, Box, Button, Typography } from '@mui/material'
import useStylesWarningAlert from 'Latest/ComponentV1/CustomMakeStyle/customWarningAlert';
import { useHistory } from 'react-router-dom';
import { useAppSelector } from 'hooks/reduxHooks';

const Pending = ({ noteTextShow }: any) => {

  const classes = useStylesWarningAlert();
  const { push } = useHistory()
  const { userAccountDetails } = useAppSelector(state => state.auth);

  const comment: any = userAccountDetails?.kyc?.comment

  const isFreezAcc = userAccountDetails?.restriction?.accountFreeze

  return (
    <>

      <Box sx={{ display: 'flex', alignItems: "flex-start", flexDirection: { xs: "column", md: "row" }, gap: { xs: "20px", md: "32px" } }}>
        <Box>
          <Alert severity="warning" className={classes.customAlert} >
            <AlertTitle className={classes.customAlertTitle}>Update your submission </AlertTitle>
            {comment != null && <Typography className={classes.customText}>{comment}</Typography>}
          </Alert>

          <Box sx={{ mt: "10px" }}>
            <StaticContent noteTextShow={true} />
          </Box>
        </Box>

        <Button variant='contained' color="secondary" onClick={() => push("/2nd-tier-kyc")} sx={{ width: "140px" }} disabled={isFreezAcc}  >Edit</Button>

      </Box>

    </>
  )
}

export default Pending