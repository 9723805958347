export const webCamStyle = {
    moadlStyle: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: { xs: "95%", sm: "80%", md: "auto", lg: 800 },
        maxHeight: "95%",
        bgcolor: 'background.paper',
        boxShadow: "0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)",
        borderRadius: "12px",
        p: "24px",
        overflow: "auto"
    },
    fullscreenWebcam: {
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "#000000ad",
        zIndex: 9999,
    },
    closeBtn: {
        position: "absolute",
        top: "10px",
        right: "10px",
        padding: "8px 12px",
        color: "#000",
        cursor: "pointer",
        zIndex: 999,
        background: "rgba(0, 0, 0, 0.7)",
        borderRadius: "60%",
        border: "none",
        boxShadow: "0 0 1px rgba(255, 255, 255, 0.5)",
    },
    captureBtn: {
        position: "absolute",
        bottom: "4rem",
        left: "50%",
        transform: "translateX(-50%)",
        cursor: "pointer",
        zIndex: 999,
    },
    frameStyle: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        paddingBottom: '60%', // 5/3 aspect ratio (3/5 * 100)
        border: '2px solid red',
        borderRadius: '12px',
        '&.loader': {
            paddingBottom: '0 !important', border: 0,
            '& .MuiBox-root': {
                height: 'auto'
            }
        }
    },
    frameStyleKyc: {
        position: 'absolute',
        top: {sm:"75%",xs:"80%"},
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: {xs:"70%",sm:"50%"}, // 50% * 1.4
        paddingBottom: {xs:"42%",sm:"30%"}, // 30% * 1.4
        border: '2px solid red',
        borderRadius: '12px',
    }
    
    
    ,
    loaderOnWebcam: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    }
}

export const SelfieWebcamStyle = {
    frameStyleKyc:{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        height: '85%',
        width:"55%",
        paddingBottom: '30%', // 5/3 aspect ratio (3/5 * 100)
        border: '2px solid red',
        borderRadius: '50%',
        '@media (max-width: 600px)': { // Mobile view adjustments
            width: '290px',
            height: '320px',
            marginLeft:'3%',
        },
    }
}