import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import moment from 'moment';
import NodataScreen from 'Latest/components/NodataScreen';
import TablePagination from '@mui/material/TablePagination';
import { Box, Chip, TableFooter, TableSortLabel, Tooltip, Typography, useTheme } from '@mui/material';
import { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { CurrencyFormatter, CurrencyFormatterWithDecimal } from 'utils/calculation';
import { StyledTableCell, StyledTableRow } from 'Latest/TableV1/customTable';
import saveImg from "Latest/Images/svg/savePlusChip.svg";
import investchip from "Latest/Images/svg/investchip.svg";
import ArrowUp from 'Latest/ComponentV1/ArrowUp';
import NoData from 'Latest/Illustration/noData';
import Pagination from '@mui/material/Pagination';
import { tableStyle } from 'Latest/DashboardScreen/GoalDashboard/Style/table';
import { labelTableStatus, transactionTableStatus, transactionTypeArrow, transactionTypeArrowSigns } from '../../../constants';
import TableStatusLabel from 'Latest/ComponentV1/TableStatusLabel';
import Skeleton from '@mui/material/Skeleton';
import { tokens } from 'theme';
import { capitalizeFirstLetter } from 'utils/textTransform';

export default function CustomizedTables({ goalDetailView = false, lists, loading, minWidth = 1000, row = 10 }: any) {
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(row);
  const [sort, setSort] = React.useState<any>({ direction: 'asc', column: 'date' });
  const [sortAmount, setSortAmount] = React.useState<any>({ direction: 'asc', column: 'amount' });
  const [sortNetAmount, setSortNetAmount] = React.useState<any>({ direction: 'asc', column: 'netAmount' });

  const customTheme = useTheme();
  const colors = tokens(customTheme.palette.mode);

  const [list, setList] = React.useState<any>(lists);

  React.useEffect(() => {
    setList(lists)
  }, [lists])

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, list.length - (page - 1) * rowsPerPage);

  // Calculate the starting and ending indices for the current page
  const startIndex = (page - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;

  const handleSort = (column: any) => {
    const isAsc = sort.column === column && sort.direction === 'asc';
    setSort({ direction: isAsc ? 'desc' : 'asc', column });
    setList((prevData: any) => prevData?.sort((a: any, b: any) => {
      const dateA: any = new Date(a.transactionDate);
      const dateB: any = new Date(b.transactionDate);
      return isAsc ? dateA - dateB : dateB - dateA;
    }));
  };

  const handleSortAmount = (column: any) => {
    const isAsc = sortAmount.column === column && sortAmount.direction === 'asc';
    setSortAmount({ direction: isAsc ? 'desc' : 'asc', column });
    setList((prevData: any) =>
      prevData?.sort((a: any, b: any) => {
        const amountA = a.amount;
        const amountB = b.amount;
        return isAsc ? amountA - amountB : amountB - amountA;
      })
    );
  };

  const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }));

  const customTooltip = (item: string, goal: string) => {

    return (
      <LightTooltip arrow title={item} placement='top' >
        <Chip label={item} sx={{
          fontSize: "12px",
          width: "130px",
          whiteSpace: "nowrap",
          background: "#D7E4FF",
          color: "#113997",
          fontWeight:"bold",
          cursor:"default",
          ...(item && item.length >= 12 ? { textOverflow: 'ellipsis', overflow: "hidden", } : {})
        }}/>
      </LightTooltip>
    )
  }

  const handleSortNetAmount = (column: any) => {
    const isAsc = sortNetAmount.column === column && sortNetAmount.direction === 'asc';
    setSortNetAmount({ direction: isAsc ? 'desc' : 'asc', column });
    setList((prevData: any) =>
      prevData?.sort((a: any, b: any) => {
        const amountA = a.amount;
        const amountB = b.amount;
        return isAsc ? amountA - amountB : amountB - amountA;
      })
    );
  };

  const totalPage = Math.ceil(list.length / rowsPerPage)
  console.log(list, "acf")

  const transactionAmtColor = (row: any) => {

    // if (row?.matchingDateObj?.hasOnlyBonus) {
    //   return
    // } else
     if (transactionTypeArrowSigns[row?.transactionType] == "+") {
      return colors.success[150]
    } else if (transactionTypeArrowSigns[row?.transactionType] == "-") {
      return colors.red[150]
    }
  }

  const typeName = (row: any) => {
    // if (row?.matchingDateObj?.hasOnlyBonus) {
    //   return capitalizeFirstLetter("Dividendreinvest")
    // }
    if (row?.transactionType.toLowerCase() == "Dividendreinvest".toLowerCase()) {
      return "Dividend-Reinvested"
    } else if(row?.transactionType.toLowerCase() == "Reversalwithdrawal".toLowerCase()){
      return "Reversal Withdrawal"
    }
    return capitalizeFirstLetter(row?.transactionType)
  }

  const arrowTransaction = (row: any) => {
    // if (row?.matchingDateObj?.hasOnlyBonus) {
    //   return <ArrowUp type={''} />
    // } else {
    //   return <ArrowUp type={transactionTypeArrow[row?.transactionType]} />
    // }

    return <ArrowUp type={transactionTypeArrow[row?.transactionType]} />

  }


  return (
    <>

      <TableContainer sx={{ boxShadow: " 0px 2px 1px -1px rgba(0,0,0,0.1), 0px 1px 1px 0px rgba(0,0,0,0.1), 0px 1px 3px 0px rgba(0,0,0,0.1)" }} >
        <Table sx={{ minWidth: minWidth }} aria-label="customized table">
          <TableHead >
            <TableRow>
              <StyledTableCell align="left" ></StyledTableCell>
              <StyledTableCell align="left" >Type</StyledTableCell>
              <StyledTableCell>
                <TableSortLabel
                  active={sort?.column === 'date'}
                  direction={sort.direction}
                  onClick={() => handleSort('date')}
                  style={{ ...(customTheme.typography as any)?.["tableHeader"], color: colors.grey[10], }}
                >
                  Date
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell align="left">
                <TableSortLabel
                  active={sortAmount?.column === 'amount'}
                  direction={sortAmount.direction}
                  onClick={() => handleSortAmount('amount')}
                  style={{ ...(customTheme.typography as any)?.["tableHeader"], color: colors.grey[10], }}
                >
                  Amount (PKR)
                </TableSortLabel>
              </StyledTableCell>

              {!goalDetailView && <StyledTableCell align="left" style={{paddingLeft:"50px"}}>Product</StyledTableCell>}

              <StyledTableCell align="left">Status</StyledTableCell>
              <StyledTableCell align="left" >Payment</StyledTableCell>

              {/* <StyledTableCell align="left">
                <TableSortLabel
                  active={sortNetAmount?.column === 'netAmount'}
                  direction={sortNetAmount.direction}
                  onClick={() => handleSortNetAmount('netAmount')}
                  style={{ ...(customTheme.typography as any)?.["tableHeader"], color: colors.grey[10], }}
                >
                  Net Invest. (PKR)
                </TableSortLabel>
              </StyledTableCell> */}
            </TableRow>
          </TableHead>

          <TableBody >
            {loading ?
              <LoadingSkeleton />
              :
              list?.length > 0 ?
                <>
                  {list?.slice(startIndex, endIndex).map((row: any) => (
                    <StyledTableRow key={row.name} >
                      <StyledTableCell align="left" style={{ fontWeight: "400", width: 1, paddingRight: 0 }}>{arrowTransaction(row)}
                      </StyledTableCell>
                      <StyledTableCell align="left" style={{ color: colors.grey[10], fontWeight: "400", width: goalDetailView ? "auto" : "12rem" }}>{typeName(row)}</StyledTableCell>
                      <StyledTableCell component="th" scope="row" style={{ color: colors.grey[5] }} > {moment(row?.transactionDate).format('MMM DD, YYYY')} </StyledTableCell>
                      <StyledTableCell align="left" style={{ color: transactionAmtColor(row) }} >
                        {`${transactionTypeArrowSigns[row?.transactionType]}`}{CurrencyFormatterWithDecimal(row.amount, false, "never", false)}
                      </StyledTableCell>
                      {!goalDetailView && <StyledTableCell align="left" >{row.productName == "Invest" ? customTooltip(`Invest: ${row?.goalName}`, row?.goalName) : <Box sx={{ width:"130px", display:"flex" , justifyContent:"center" }}><img src={saveImg} /></Box>}</StyledTableCell>}
                      <StyledTableCell align="left" >
                        <TableStatusLabel label={labelTableStatus?.[row.status]?.label} status={labelTableStatus?.[row.status]?.status} />
                      </StyledTableCell>
                      <StyledTableCell align="left" style={{ color: colors.grey[5] }}  >{row.paymentGateway}</StyledTableCell>

                      {/* <StyledTableCell align="left" style={{ color: colors.grey[5] }} >
                        {CurrencyFormatterWithDecimal(row.netValue, false, "never", false)}
                      </StyledTableCell> */}

                    </StyledTableRow>

                  ))}
                  {(totalPage > 1 || goalDetailView) && emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: (65) * emptyRows,
                      }}
                    >
                      <TableCell colSpan={8} />
                    </TableRow>
                  )}
                </>
                :
                <TableRow style={{ height: (65) * emptyRows }} >
                  <TableCell colSpan={8} >
                    <NoData />
                  </TableCell>
                </TableRow>
            }
          </TableBody>

          {list?.length > 0 && <TableFooter sx={{ borderTop: "1px solid #EAECF0", boxShadow: "none" }}>
            <TableRow>
              <TableCell colSpan={8} align="center">
                <Box style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                  <Pagination
                    count={totalPage}
                    page={page}
                    onChange={handleChangePage}
                    sx={{ "& .Mui-selected": { background: "#0000001F" }, color: "rgba(0, 0, 0, 0.87)" }}
                  />
                </Box>
              </TableCell>
            </TableRow>
          </TableFooter>}
        </Table>
      </TableContainer>

    </>
  );
}

const LoadingSkeleton = () => {
  return (
    <StyledTableRow  >
      <StyledTableCell align="center" style={{ fontWeight: "400", width: 5 }}> <Skeleton variant="circular" width={40} height={40} /> </StyledTableCell>
      <StyledTableCell component="th" scope="row"> <Skeleton variant="text" sx={{ fontSize: '1rem' }} />  </StyledTableCell>
      <StyledTableCell align="center" ><Skeleton variant="text" sx={{ fontSize: '1rem' }} /></StyledTableCell>
      <StyledTableCell align="center" ><Skeleton variant="text" sx={{ fontSize: '1rem' }} /></StyledTableCell>
      <StyledTableCell align="center" ><Skeleton variant="text" sx={{ fontSize: '1rem' }} /></StyledTableCell>
      <StyledTableCell align="center" ><Skeleton variant="text" sx={{ fontSize: '1rem' }} /></StyledTableCell>
      <StyledTableCell align="left" ><Skeleton variant="text" sx={{ fontSize: '1rem' }} /> </StyledTableCell>
    </StyledTableRow>
  )
}