import { Alert, AlertTitle, Box, IconButton, Typography } from '@mui/material';
import useStylesErrorAlert from 'Latest/ComponentV1/CustomMakeStyle/customErrorAlert';
import { useAppSelector } from 'hooks/reduxHooks';
import React from 'react'
import IconDanger from "Latest/Images/svg/IconDanger.svg";

const TierKycRejectAlert = ({ handleClose }: any) => {

    const classes = useStylesErrorAlert();
    const { userAccountDetails } = useAppSelector(state => state.auth);

    return (
        <>
            <Box>
                <Alert severity="error" className={classes.customAlert} sx={{ position: "relative" ,  '& .MuiAlert-action': {
                    padding:"0px !important",
                    height:"20px !important",
      '& .MuiButtonBase-root': {
         padding:"0px !important",
         
      }
    }}} onClose={handleClose} icon={false}>

                    <AlertTitle className={classes.customAlertTitle} sx={{ fontWeight: 500 }}>
                        <img src={IconDanger} />
                        Upgrade could not be approved
                    </AlertTitle>

                    {userAccountDetails?.kyc?.comment ?
                        <Typography  className={classes.customText} sx={{wordWrap:"break-word"}}>{userAccountDetails?.kyc?.comment}</Typography>
                        :
                        <Typography className={classes.customText}>Our compliance team has marked your account as high risk</Typography>
                    }

<Typography component={"p"}  fontWeight={400} sx={{ display: 'inline',fontSize: '14px',width:"100%",wordWrap:"break-word",wordBreak:"break-all" }}>
        Feel free to contact us&nbsp;
        <Typography component={"span"} sx={{ textDecoration: 'underline', fontWeight: 500, color: '#541313', fontSize: '14px !important', display: 'inline', fontStyle:"normal",lineHeight:"normal" }}>
          fund-ops@mahaana.com
        </Typography>
        &nbsp;for further details.
      </Typography>
                </Alert>
            </Box>
        </>
    )
}

export default TierKycRejectAlert