import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material'
import React, { useRef, useState } from 'react'
import FormikReactSelect from "jsx/components/Shared/V1/FormikReactSelectV1"
import { useFormik } from 'formik'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import useStyles from 'Latest/ComponentV1/CustomMakeStyle/CustomButtonNeutral'
import * as Yup from 'yup';
import DetailCardComp from '../Component/detailCardComp'
import { useHistory } from 'react-router-dom'
import { depositDetailCFArr, depositDetailGIArr, goalCategory, mixPannelTrackerEventName } from '../../../constants'
import { OnlineBankFormValue } from "redux/reducers/payment";
import { AxiosResponse } from 'axios'
import { links } from "static/links";
import { CurrencyFormatterWithDecimal } from 'utils/calculation'
import { StylesConfig } from 'react-select'
import BankDetailCard from '../Component/bankDetailCard'
import { useAppLoading } from 'hooks/appLoading'
import { CSSObject } from 'styled-components'
import ButtonRowV1 from 'Latest/ComponentV1/ButtonRowV1'
import { MixPannelTracker } from 'Latest/MixPanel/MixPannelTracker'
import { onGetTransactionDetail } from 'redux/reducers/sharedStateData'

import BankDetailGrid from 'Latest/MyProfile/BankDetails/bankDetailGrid'
import warningIcon from 'Latest/Images/svg/WarningDiamond.svg'
import BankIcon from 'Latest/Images/svg/Bank.svg'
import copyicon from "Latest/Images/svg/copyicon.svg"
import uploadIcon from "Latest/Images/svg/uploadIconV2.svg"
import { pxToRem } from 'utils/V1/getFontValues'
import Checkmark from 'react-typescript-checkmark'
import CustomUpload from 'Latest/ComponentV1/CustomUpload'
import { toast } from 'react-toastify'



const upperPDFErr = "Please upload receipt."

type Field = { label: string; value: string };
export const customStyleSelectPayment: StylesConfig<Field, false> = {
    control: (provided: CSSObject, state) => {
        return {
            ...provided,
            border: 'none',
            borderRadius: '8px',
            boxShadow: "0px 3px 1px -1px #1A1A1A12, 0px 1px 0px 0px #CCCCCC80 inset, 0px -1px 0px 0px #0000002B inset, -1px 0px 0px 0px #00000021 inset, 1px 0px 0px 0px #00000021 inset"
        };
    },
    valueContainer: (provided: CSSObject, state) => {
        return {
            ...provided,
            minHeight: state.hasValue ? '80px' : "auto", // Adjust the height as needed,
        };
    },
    menuList: (provided: CSSObject, state) => {
        return {
            ...provided,
            minHeight: "80px", // Adjust the height as needed,
        };
    },
};

const BankDeposit = ({ banksList, categoryAcc, formik, goalId, onSuccessTransaction, originalAmt, paymentAccList, previousStep, receiptCategory, submitBankTransfer, ...props }: any) => {

    const loading = useAppLoading('payment');
    const dispatch = useAppDispatch();
    const [openAccModal, setOpenAccModal] = useState(false);
    const investment = localStorage.getItem('InvestmentAmount');
    const initial = localStorage.getItem('InitialAmount');
    const { userAccountDetails } = useAppSelector(state => state.auth);
    const { goalIdPost } = useAppSelector(state => state?.portfolio);
    const { location, push } = useHistory();
    const loc: any = location.state
    const [openErrModal, setOpenErrModal] = useState(false);
    const { addGoalBody } = useAppSelector(state => state.sharedData);
    const [filterBankList, setFilterBankList] = useState([])

    const theme = useTheme();
    const smScreen = useMediaQuery(theme.breakpoints.down('sm'));

    // const amt = goalId ? (goalId === CASH_FUND_ID ? (addGoalBody?.initialAmount) : formik?.values?.initial_amount) : loc?.amount?.investAmount
    const { user: { email } } = useAppSelector((state: any) => state.auth);

    const UserAccIban = userAccountDetails?.iban || "";

    // const flowAccCategory = goalId == CASH_FUND_ID ? goalCategory.CashFund : goalCategory.GeneralInvest

    // const categoryAcc = loc?.key == "Sell" ? loc?.category : flowAccCategory

    const classes = useStyles();

    const { userBankAcc } = useAppSelector(state => state.sharedData)

    const SellCFBody = {
        "idCashFundInvestment": loc?.idCashFundInvestment,
        "investAmount": loc?.amount?.investAmount
    }

    const formikIbft = useFormik({
        enableReinitialize: true,
        initialValues: { proofOfPayment: '', selectedAccNo: UserAccIban, term1: false, transactionID: "" },
        onSubmit: (values: any) => {
            const { proofOfPayment, selectedAccNo, transactionID } = values;
            const FilterAccDetail = userBankAcc.filter((item: any, index: number) => item.accountNumber == selectedAccNo)

            const formData = new FormData();
            formData.append('accountNumber', selectedAccNo);
            formData.append('accountTitle', FilterAccDetail[0]?.accountTitle);
            formData.append('bankName', FilterAccDetail[0]?.bankName);
            formData.append('paymentReceipts[0].key', receiptCategory);
            formData.append('paymentReceipts[0].value', proofOfPayment);
            formData.append('goalId', loc?.key == "Sell" ? loc?.idCashFundInvestment : goalIdPost);
            formData.append('paymentGateway', "IBFT");
            formData.append('accountCategory', categoryAcc);
            formData.append('transactionReferenceNumber', transactionID || "-");
            formData.append('originalAmount', originalAmt);


            dispatch(OnlineBankFormValue(formData, onSuccessResponse));
            return;
            // eslint-disable-next-line no-console
        },
        validationSchema: Yup.object().shape({
            // term1: Yup.bool().oneOf([true], 'Please read and agree to our terms & conditions.'),
            selectedAccNo: Yup.string().required('Please select a bank account for deposit.'),
            proofOfPayment: Yup.mixed().required(upperPDFErr)
                .test('fileType', 'Invalid file type. Only PNG, JPG, or PDF files are allowed.', (value: any) => {
                    if (value instanceof File) {
                        const allowedFileTypes = ['image/png', 'image/jpeg', 'image/jpg', 'application/pdf'];
                        const fileType = value.type;
                        return allowedFileTypes.includes(fileType);
                    }
                    return true;
                })
        })
    });


    // const onSuccess = (message: string, response: any) => {
    //     push(links.PayReceipt, { ...loc, "transactionReferenceNumber": formikIbft.values.transactionID });
    // }
    // const onFail = (message: string, response: AxiosResponse) => {
    //     console.log(response.status)
    //     setOpenErrModal(true)
    // }

    const getTransactionHistory = () => { //to check out transaction history for mixpanel 
        const onSuccess = (message: any, response: any) => {
            const filterTransactionDetail = message.filter((item: any, index: number) => item.goalId == goalIdPost)
            if (categoryAcc.toLowerCase() == goalCategory.CashFund.toLowerCase()) {
                filterTransactionDetail.length == 1 && MixPannelTracker({ eventName: mixPannelTrackerEventName.initial_transaction_submitted, email })
                filterTransactionDetail.length == 1 && Number(originalAmt) >= 10000 && MixPannelTracker({ eventName: mixPannelTrackerEventName.tenK_crossed, email })
            }
        }
        const onFail = (message: string, response: any) => {
            console.log(message, response.status, "OPOOP")
        }
        const param = {
            id: ""
        }
        dispatch(onGetTransactionDetail(param, onSuccess, onFail))
    }

    const onSuccessResponse = (message: string, response: AxiosResponse) => {
        // if (loc?.key == "Sell") {
        //     dispatch(SellCf(SellCFBody, onSuccess, onFail))
        // } else {
        //     push(links.PayReceipt, { ...loc, "transactionReferenceNumber": formikIbft.values.transactionID });
        // }
        onSuccessTransaction({ ...loc, "transactionReferenceNumber": formikIbft.values.transactionID })
        // push(links.PayReceipt, { ...loc, "transactionReferenceNumber": formikIbft.values.transactionID });
        getTransactionHistory()
        return;
    };

    React.useEffect(() => {
        if (userBankAcc?.length > 0) {
            const filterKey = userBankAcc?.map((item: any, ind: number) => {
                const icon = banksList?.find((x: any) => x.label === item.bankName)?.image;

                return (
                    {
                        "label": `
                            <div style="display: flex;">
                                <img src=${icon} height="55" width="auto" />
                                <div>
                                    <span style="color: #616A75;font-size: 14px;text-transform: capitalize;">${item.accountTitle}</span>
                                    <br/>
                                    <span style="color: #1D2939;font-size: 20px;font-weight: 500;line-height: 30px;">${item.accountNumber}</span>
                                </div>
                            </div>
                        `,
                        // "label": `${item.bankName}<br/>${item.accountTitle}<br/> ${item.accountNumber}`,
                        "value": item.accountNumber
                    }
                )
            })
            setFilterBankList(filterKey)
        }
    }, [userBankAcc, banksList])

    const handleGoBack = (e: any) => {
        if (loc?.key == "Sell") {
            push(links.home)
        } else {
            e.preventDefault();
            previousStep();
        }
    }

    const depositArr = receiptCategory == goalCategory?.GeneralInvest ? depositDetailGIArr : depositDetailCFArr

    const buttonHtml = () => {
        return (
            <ButtonRowV1 handleBack={handleGoBack} handleNext={() => formikIbft.handleSubmit()} loadingNextBtn={loading} btnTitleNext={"Submit"} checkFreezAcc={true} />
        )
    }

    const impInfo = [
        `Please only transfer funds from a bank account that’s in <span class="MuiTypography-root MuiTypography-body2 css-vu8fyq-MuiTypography-root" style="color:#821B13; font-weight:600">YOUR NAME.</span>`,
        `If you transfer from a bank account not in your name, your deposit will be reverted in 1 month.`,
        "You must submit proof of payment. Your transaction will not be processed otherwise. "
    ]


    return (
        <>
            <ImportantInfoComp data={impInfo} />

            {smScreen && <>
                <Typography variant='h1' color="#422E84" sx={{ pb: '12px', pt: '24px' }}>Upload proof of payment</Typography>
                <CustomUpload formik={formikIbft} fieldName={"proofOfPayment"} upperPDFErr={upperPDFErr} tooltip={"File format: JPG, PNG, PDF"} />
            </>}

            <Typography variant='h1' color="#422E84" sx={{ p: categoryAcc != "GeneralInvestment" ? { xs: "16px 0px", md: "24px 0 12px 0" } : { xs: "0px 0px 16px 0px", md: "0px 20px 12px 20px" } }}>Deposit in Mahaana’s bank account</Typography>

            <BankAndAmtGrid originalAmt={originalAmt} />

            {!smScreen && <>
                <Typography variant='h1' color="#422E84" sx={{ pb: '12px', pt: '24px' }}>Upload proof of payment</Typography>
                <CustomUpload formik={formikIbft} fieldName={"proofOfPayment"} upperPDFErr={upperPDFErr} tooltip={"File format: JPG, PNG, PDF"} />
            </>}

            <Box sx={{ pt: userBankAcc?.length ? "24px" : "10px" }}>

                <Grid container md={12} xs={12}>

                    <Grid item md={12} xs={12}>
                        {userBankAcc?.length ? <Typography variant='h1' color="#422E84" sx={{ pb: '12px' }}>Your bank account</Typography> : null}

                        {userBankAcc?.length ?
                            <Box>
                                {userBankAcc?.length > 1 ?
                                    <Box sx={{ maxWidth: "470px" }} >
                                        <FormikReactSelect
                                            required
                                            disabled={false}
                                            fieldName='selectedAccNo'
                                            fields={filterBankList}
                                            formik={formikIbft}
                                            // label='Select your deposit bank account'
                                            customStyle={customStyleSelectPayment}
                                            onChange={(val, option) => {
                                                formikIbft.setFieldValue('selectedAccNo', option.value);
                                            }}
                                            placeholder='Select your bank account'
                                            value={filterBankList.find((el: any) => el?.value == formikIbft?.values?.selectedAccNo)}
                                        />
                                    </Box>
                                    :
                                    <BankDetailCard iban={userAccountDetails?.iban} userBankList={userBankAcc} />
                                }
                            </Box>
                            : null}
                    </Grid>

                </Grid>

                {!smScreen && <Box sx={{ mt: { xs: "24px", md: "40px" } }}>
                    {buttonHtml()}
                </Box>}

            </Box>

            {
                smScreen && <Box sx={{ p: "8px 16px", mt: "24px", background: "white" }}>
                    {buttonHtml()}
                </Box>
            }

        </>
    )
}

export default BankDeposit;


const ImportantInfoComp = ({ data }: any) => {
    return (
        <Box sx={{ borderBottom: "1px solid #D0D5DD40", borderRadius: "8px", pt: '12px', pb: '8px', backgroundColor: "#FEE9E8" }}>
            <Box sx={{ px: '1rem', display: "flex", alignItems: 'center', gap: '4px', mb: '8px' }}>
                <img src={warningIcon} height={20} width={20} />
                <Typography variant='h6' color="#821B13">Important information</Typography>
            </Box>

            <Box px={5}>
                {
                    data?.map((info: any) => {
                        return (
                            <Typography key={info} sx={{ listStyleType: 'disc', display: 'list-item' }} variant='body2' color={"#101828D9"} dangerouslySetInnerHTML={{ __html: info }} />
                        )
                    })
                }
            </Box>
        </Box>
    )
}

const BankAndAmtGrid = ({ originalAmt }: any) => {

    const [textToCopy, setTextToCopy] = useState(false)
    const handleSaveClipBoard = async (copyMe: string) => {
        await navigator.clipboard.writeText(copyMe)
        setTextToCopy(true);
        toast.success('Text has been copied.', { theme: "colored" });
        setTimeout(() => {
            setTextToCopy(false)
        }, 3000);
    }

    return (
        <Grid container spacing={2}>
            <Grid item md={6.5} sm={6.5} xs={12}>
                <Box sx={{
                    borderRadius: "8px", p: { xs: "8px 16px", md: "16px" }, minHeight: '108px',
                    boxShadow: "0px 3px 1px -1px #1A1A1A12, 0px 1px 0px 0px #CCCCCC80 inset, 0px -1px 0px 0px #0000002B inset, -1px 0px 0px 0px #00000021 inset, 1px 0px 0px 0px #00000021 inset",
                    display: 'flex', gap: '8px', alignItems: 'center'
                }}>
                    <img src={BankIcon} height={55} width={55} />

                    <Box>
                        <Typography variant='body2' color={"#616A75"}>Central Depositary Company of Pakistan Trustee Mahaana Islamic Cash Plan</Typography>
                        <Typography sx={{ fontWeight: 500, fontSize: pxToRem(20), lineHeight: "30px", wordBreak: "break-all" }} color={"#1D2939"}>PK77FAYS3554390000003770</Typography>
                    </Box>
                    <img src={copyicon} style={{ cursor: "pointer" }} onClick={() => handleSaveClipBoard("PK77FAYS3554390000003770")} />
                </Box>
            </Grid>
            <Grid item md={5.5} sm={5.5} xs={12}>
                <Box sx={{
                    p: { xs: "8px 16px", md: "16px" }, minHeight: '108px', height: "100%",
                    background: "#422E84", borderRadius: "8px", display: 'flex',
                    justifyContent: 'center', alignItems: 'center', flexDirection: 'column'
                }}>
                    <Typography variant='body2' color="#FFFFFFE5" mb={1}>Deposit Amount</Typography>
                    <Typography variant='h1' color={"#FFFFFFE5"} >{CurrencyFormatterWithDecimal(originalAmt, true, "never", true)}</Typography>
                </Box>
            </Grid>
        </Grid>
    )
}