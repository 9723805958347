import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Skeleton,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import settingCircleIcon from "Latest/Images/svg/settingCircleIcon.svg";
import LoadingBtnCustom from 'Latest/ComponentV1/LoadingBtnCustom';
import LoadingBtnCustomBack from 'Latest/ComponentV1/LoadingBtnCustomBack';
import { useHistory } from 'react-router-dom';
import { CurrencyFormatterWithDecimal } from 'utils/calculation';
import { useAppSelector } from 'hooks/reduxHooks';
import { pxToRem } from 'utils/V1/getFontValues';
import warningIcon from 'Latest/Images/svg/WarningDiamond.svg';
import Raastlogo from 'Latest/Images/svg/Raastlogo.svg';
import { MethodToggle } from './MethodToggle';
import banksim from 'Latest/Images/svg/banksim.svg'
import person from 'Latest/Images/svg/person.svg'
import CDC from 'Latest/Images/svg/CDC.svg'
import stars from 'Latest/Images/svg/stars.svg'
import copy from 'Latest/Images/svg/copy.svg'
import { toast } from 'react-toastify'


interface StepMethodProps {
  steps: { icon: string, description: string }[];
  methodName: string;
}

interface ImportantInfoProps {
  data: string[];
}

const GenerateIdViewRaast = ({ categoryAcc, originalAmt, previousStep ,raastId}: any) => {
  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { push } = useHistory();
  const { userAccountDetails } = useAppSelector(state => state.auth);
  const [textToCopy, setTextToCopy] = useState(false);
  const [method1Visible, setMethod1Visible] = useState(true);

  const handleSaveClipBoard = async (copyMe: string) => {
    await navigator.clipboard.writeText(copyMe)
    setTextToCopy(true);
    toast.success('Text has been copied.', { theme: "colored" });
    setTimeout(() => {
        setTextToCopy(false)
    }, 3000);
}

  const buttonHtml = () => {
    return (
      <Box 
      sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        gap: { xs: '40px' },  // Adjust gap for mobile (xs) and larger screens (sm)
        ml: { xs: '20px' }     // Adjust margin-left for mobile (xs) and larger screens (sm)
      }}
    >
      <LoadingBtnCustomBack 
        handleOnClick={previousStep ? previousStep : () => push("/")} 
        btnTitle={"Back"} 
      />
      
      <LoadingBtnCustom 
        handleOnClick={() => push("/")} 
        btnTitle={"Go to dashboard"} 
        fullWidth={smScreen} 
      />
    </Box>
    
    );
  };

  const ImportantInfoComp = ({ data }: ImportantInfoProps) => {
    return (
      <Box sx={{ borderBottom: "1px solid #D0D5DD40", borderRadius: "8px", pt: '12px', pb: '8px', backgroundColor: "#FEE9E8", marginTop: "25px" }}>
        <Box sx={{ px: '1rem', display: "flex", alignItems: 'center', gap: '4px', mb: '8px' }}>
          <img src={warningIcon} height={20} width={20} alt="Warning" />
          <Typography variant='h6' color="#821B13">Important information</Typography>
        </Box>
        <Box px={5}>
          {data?.map((info: string) => (
            <Typography key={info} sx={{ listStyleType: 'disc', display: 'list-item' }} variant='body2' color={"#101828D9"} dangerouslySetInnerHTML={{ __html: info }} />
          ))}
        </Box>
      </Box>
    );
  };
  

  const StepMethod: React.FC<StepMethodProps> = ({ methodName, steps }) => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          p: '16px',
          
        }}
      >
        {!smScreen && (
          <Typography variant='h6' color='#616A75' mb={2}>
            {methodName}
          </Typography>
        )}
        {steps.map((step, index) => (
          <Box
            key={index}
            sx={{
              display: 'flex',
              alignItems: 'center',
              mb: '16px',
              border: '1px solid #CCCCCC80',
              borderRadius: '8px',
              p: '16px',
              width: {
                xs: '100%',  // Increase width to 90% on extra-small (mobile) screens
                sm: '100%', // Full width on small and larger screens
              },
              marginRight:{
                xs: "10px"
              }
            }}
          >
            <img src={step.icon} height={40} width={40} alt={`Step ${index + 1}`} />
            <Box ml={2}>
              <Typography variant='body2' color='#666666'>
                Step {index + 1}
              </Typography>
              <Typography variant='body2' color='#281C51' fontWeight={600} sx={{ fontSize: { xs: '16px', sm: '18px' } }}>
                {step.description}
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>
    );
  };

  const method1Steps = [
    {
      icon: banksim, 
      description: 'Log into your bank app',
    },
    {
      icon: person,
      description: 'Create a new beneficiary / payee (with IBAN)',
    },
    {
      icon: CDC,
      description: 'Select Central Depository Company in the list of banks',
    },
    {
      icon: stars,
      description: 'Enter your Raast Investment IBAN and submit your transaction',
    },
  ];

  const method2Steps = [
    {
      icon: banksim,
      description: 'Log into your bank app',
    },
    {
      icon: Raastlogo,
      description: 'Select Raast payment (with IBAN)',
    },
    {
      icon: stars,
      description: 'Enter your Raast Investment IBAN and submit your transaction',
    },
  ];
  
    const handleClick = () => {
      window.location.href = 'https://www.cdcpakistan.com/';
    }

  return (
  <>
  <ImportantInfoComp data={[
  `Please only transfer funds from a bank account that’s in <span style="color:#821B13; font-weight:600">YOUR NAME.</span>`,
  "If you transfer from a bank account not in your name, your deposit will be reverted in 1 month.",
  "You must submit proof of payment. Your transaction will not be processed otherwise."
]} />

<Typography variant='h1' color="#422E84" sx={{ marginLeft: "8px", p: { xs: "16px 0px", md: "24px 0 12px 0" }, fontSize: { xs: "19px" } }}>
  Your Raast Investment ID IBAN generated by CDC:
</Typography>

<Box sx={{ pt: { xs: "8px", md: "8px" } }}>
  <Grid container spacing={2}>
    <Grid item md={6.5} sm={6.5} xs={12}>
      <Box sx={{
        borderRadius: "8px",
        p: { xs: "4px 8px", md: "16px" },
        minHeight: { xs: '80px', md: '108px' },
        boxShadow: "0px 3px 1px -1px #1A1A1A12, 0px 1px 0px 0px #CCCCCC80 inset, 0px -1px 0px 0px #0000002B inset, -1px 0px 0px 0px #00000021 inset, 1px 0px 0px 0px #00000021 inset",
        display: 'flex', gap: '8px', alignItems: 'center'
      }}>
        <img src={Raastlogo} height={55} width={55} alt="Raast Logo" />
        <Box >
          <Typography variant='body2' color={"#616A75"}  sx={{ fontSize: { lg: '18px'}}}>MICP - {userAccountDetails?.identityCard?.name}</Typography>
          <Typography sx={{ fontWeight: 500, lineHeight: "30px", marginRight:"20px",fontSize: { lg: '18px'} }} color={"#1D2939"}>{raastId}</Typography>
          
        </Box>
        <img src={copy} style={{ cursor: 'pointer' }} onClick={() => handleSaveClipBoard(raastId)} />
      </Box>
    </Grid>
    <Grid item md={5.5} sm={5.5} xs={12}>
      <Box
        onClick={handleClick}
        sx={{
          p: { xs: "4px 8px", md: "16px" }, // Reduce padding on mobile
          minHeight: { xs: '80px', md: '108px' }, // Reduce minHeight on mobile
          height: "80%",
          background: "#422E84",
          borderRadius: "8px",
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          width: { lg: '100%' },
          cursor: 'pointer' // Show pointer cursor to indicate it's clickable
        }}
      >
        <Typography variant='body2' color="#FFFFFFE5" mb={1}>Deposit Amount</Typography>
        <Typography variant='h1' color={"#FFFFFFE5"}>
          {CurrencyFormatterWithDecimal(originalAmt, true, "never", true)}
        </Typography>
      </Box>
    </Grid>
  </Grid>

  <Typography variant='h1' color="#422E84" sx={{ marginLeft: "8px", p: { xs: "16px 0px", md: "24px 0 12px 0" }, fontSize: { xs: "17px", lg: "20px" }, marginTop: { xs: "15px" } }}>
    Steps to make a Raast transaction
  </Typography>

  {smScreen ? (
    <>
      <MethodToggle method1Visible={method1Visible} setMethod1Visible={setMethod1Visible} />
      {method1Visible ? (
        <StepMethod steps={method1Steps} methodName='Method 1' />
      ) : (
        <StepMethod steps={method2Steps} methodName='Method 2' />
      )}
    </>
  ) : (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        gap: '16px',
        border: 'none'
      }}
    >
      <StepMethod steps={method1Steps} methodName='Method 1' />
      <StepMethod steps={method2Steps} methodName='Method 2' />
    </Box>
  )}
</Box>

<Box sx={{
  display: "flex",
  justifyContent: 'flex-end', // Aligns the buttons to the right
  alignItems: { xs: "flex-start", sm: "center" },
  gap: "8px",
  flexDirection: { xs: "column", sm: "row" }
}}>
  {buttonHtml()}
</Box>

  </>

  )};

export default GenerateIdViewRaast;
