/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable sort-keys */
/* eslint-disable no-param-reassign */
/* eslint-disable no-extend-native */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import * as React from 'react'
import { useState, useEffect, useRef } from 'react'
import { Box, Container, Grid, Typography, Tabs, Tab, FormControl, InputAdornment, FormLabel, Button, IconButton, List, ListItem, Skeleton, Divider, useTheme, useMediaQuery } from '@mui/material'
import { PayNowStyles } from './style'
import * as yup from 'yup';
import InputField from 'jsx/components/Shared/FormikInputFieldLatest';
import { useFormik } from 'formik';
import { links } from 'static/links';
import { useHistory } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { useDispatch } from 'react-redux';
import { bankTransferPost, confirmPaymentModalAction, getPaymentAcc, getServiceFee, NIFTBankFormValue, onBankTransfer, OnlineBankFormValue, SellCf } from 'redux/reducers/payment';
import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { CASH_FUND_ID, FlowPath, GoalNameEnum, goalCategory } from '../../constants';
import arrow from '../Images/rightArrow.png'
import { CurrencyFormatter, CurrencyFormatterWithDecimal } from 'utils/calculation';
import AddLocationIcon from '@mui/icons-material/AddLocation';
import { OnlineBankFormSchema } from 'validate/paymnet-options/bank-transfer';
import ConfirmPayment from 'jsx/components/Shared/ConfirmationPayment';
import MultipleAccount from '../../jsx/components/PaymentOptions/MultipleAccDialog';
import SelectField from '../../jsx/components/Shared/FormikSelectField';
import { Col, Row } from 'react-bootstrap';
import { Field } from '../../jsx/components/Shared/FormikSelectField';
import { onGetBanks, onGetInvestProductsValue, onGetUserBankAccount } from 'redux/reducers/sharedStateData';
import NiftLogo from "Latest/Images/nift.png";
import { goalStatus } from 'redux/actions';
import Loader from 'jsx/components/Shared/Loader';
import PayNowResModal from './MsgModal';
import Big from 'big.js';
import ErrModal from 'Latest/MyProfile/Modal/errModal';
import { ModalStyle } from 'Latest/MyProfile/Style/modalStyle';
import { HeadingStyle, juniorHeadingStyle, linkSpan } from 'Latest/Style/Main';
import { useAppLoading } from 'hooks/appLoading';
import { LandingPageURL } from 'constants/landingPageURL';
import { IbanBankValidator } from 'utils/validator';
import PayMob from './PaymentMethod/payMob';
import { removeCommaFormatting } from 'utils/commaSeparateMasking';
import IBFT from './PaymentMethod/IBFT';
import TopBarHeading from 'Latest/ComponentV1/topBarHeading';
import BankDepositSavePlusV2 from './PaymentMethodV1/BankDepositSavePlusV2';
// import BankDepositSavePlus from './PaymentMethodV1/BankDepositSavePlus';
import CustomTab from 'Latest/ComponentV1/CustomTab'
import PayMobCard from './PaymentMethodV1/PayMobCard';
import BankDepositSaveGI from './PaymentMethodV1/BankDepositSaveGI';
import Raast from './PaymentMethodV1/Raast';
import CustomBoxTab from 'Latest/ComponentV1/CustomTab/CustomBoxTabV2';
import BankIconV2 from "Latest/Images/svg/BankIconV2";
import CardIcon from "Latest/Images/svg/CardIconV2";
import RaastLogo from "Latest/Images/svg/Raast-Logo";
import { mainContainerV2 } from 'Latest/Style/Style';
import ExitIcon from "Latest/Images/svg/ExitIcon";
import TopBarLogo from 'Latest/ComponentV1/topBarLogo';


const PayNow = ({ formik, goalId, goalValue, loading, nextStep, paymentNextStep, previousStep, setLoading, submitGoal, ...props }: any) => {
    const dispatch = useDispatch();
    const productsSegregationLoading = useAppLoading('productsSegregation');
    const { location, push } = useHistory();
    const theme = useTheme();
    const smScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const { addGoalBody } = useAppSelector(state => state.sharedData);
    const { banksList } = useAppSelector(state => state.sharedData);
    const { paymentAcc, serviceFee } = useAppSelector(state => state.payment);
    const loc: any = location.state
    const { goalFlowId, goalFlowPath, goalIdPost } = useAppSelector(state => state?.portfolio);
    const amt = goalId ? (goalId === CASH_FUND_ID ? (addGoalBody?.initialAmount) : formik?.values?.initial_amount) : loc?.amount?.investAmount

    const [productsSegregation, setProductsSegregation] = useState<any>({})

    const isCFProdAmt = productsSegregation?.CashFund > 0;
    const isGIProdAmt = productsSegregation?.GeneralInvestment > 0;
    const goalPostId = goalFlowPath == FlowPath.Wizard ? goalIdPost : loc?.idCashFundInvestment
    const flowAccCategory = goalId == CASH_FUND_ID ? goalCategory.CashFund : goalCategory.GeneralInvest
    const categoryAcc = loc?.key == "Sell" ? loc?.category : flowAccCategory
    const receiptCategory = categoryAcc == goalCategory.CashFund ? goalCategory.CashFund : isGIProdAmt ? goalCategory.GeneralInvest : goalCategory.CashFund

    const routesList = ["/Cashfund", "/Goal"];
    const isPathInList = routesList.includes(location.pathname);


    useEffect(() => {
        submitGoal && submitGoal()
    }, [formik?.values])

    useEffect(() => {
        dispatch(onGetBanks());
        dispatch(getPaymentAcc())
    }, [dispatch]);

    useEffect(() => {
        dispatch(onGetUserBankAccount())
    }, [])


    const gotoReceipt = (data: any) => {
        // if (nextStep) {
        //     data.initAmount = formik?.values?.initial_amount;
        //     nextStep(data)
        // } else {
        //     push(links.PayReceipt, data);
        // }
        push(links.PayReceipt, data);
    }

    const onClickBackBtn = (e: any) => {
        if (loc?.key == "Sell") {
            push(links.home)
        } else {
            e.preventDefault();
            previousStep();
        }
    }

    const contentTabs = [
        ...(categoryAcc != "GeneralInvestment"
            ? [
                {
                    label: 'Raast',
                    value: 'Raast',
                    icon: RaastLogo,
                    comp: (
                        <Container maxWidth="lg" sx={{ px: '0px!important', pt: '16px' }}>
                            <Raast
                                previousStep={previousStep}
                                goalId={goalId}
                                formik={formik}
                                originalAmt={amt}
                                categoryAcc={categoryAcc}
                                onSuccessTransaction={gotoReceipt}
                                goalPostId={goalPostId}
                            />
                        </Container>
                    ),
                },
            ]
            : []),

        {
            label: 'Bank deposit', value: "BankDeposit", icon: BankIconV2,
            comp:
                <Container maxWidth="lg" sx={{ px: "0px!important", pt: "40px" }}>
                    <BankDepositSavePlusV2
                        banksList={banksList}
                        paymentAccList={paymentAcc}
                        previousStep={previousStep}
                        goalId={goalId}
                        formik={formik}
                        productsSegregation={productsSegregation}
                        originalAmt={amt}
                        categoryAcc={categoryAcc}
                        receiptCategory={receiptCategory}
                        onSuccessTransaction={gotoReceipt}
                    />
                </Container>
        },
        {
            label: 'Card', value: "Card", icon: CardIcon, comp:
                <Container maxWidth="lg" sx={{ px: "0px!important", pt: "16px" }}>
                    <PayMobCard
                        previousStep={previousStep}
                        goalId={goalId}
                        formik={formik}
                        originalAmt={amt}
                        categoryAcc={categoryAcc}
                        onSuccessTransaction={gotoReceipt}
                    />
                </Container>
        },
        // {
        //     label: 'Pay Online', value: "PayOnline", comp:
        //         <>
        //         </>
        // },
    ]

    let goalName = "";
    const GoalNameArr = Object.values(GoalNameEnum);

    if (goalFlowPath == FlowPath.Wizard) {
        goalName = goalId === CASH_FUND_ID ? "Save +" : formik?.values?.goalId == 4 ? `Invest: ${formik?.values?.goal_name}` : `Invest: ${GoalNameArr[formik?.values?.goalId]}`
        // goalName = goalId === CASH_FUND_ID ? "Save +" : formik?.values?.goalId == 4 ? `Invest: ${formik?.values?.goal_name}` : `Invest: ${goalValue}`
    } else {
        goalName = loc?.category == "CashFund" ? "Save +" : `Invest: ${loc?.amount?.selectedProductName}`
    }

    const handlePayNowScreen = () => {

        const isFlowPathWizard = goalFlowPath == FlowPath.Wizard

        const isCategoryGI = loc?.category == "GeneralInvestment";

        if ((isFlowPathWizard && goalId != CASH_FUND_ID && isGIProdAmt && isCFProdAmt) || (isCategoryGI && isGIProdAmt && isCFProdAmt)) {
            return (
                <>
                    <Container maxWidth="lg" sx={{ px: "0px!important" }}>
                        <Box
                            sx={{ p: { xs: "16px", sm: "30px" } }}
                        >
                            <BankDepositSaveGI
                                banksList={banksList}
                                paymentAccList={paymentAcc}
                                previousStep={previousStep}
                                goalId={goalId}
                                formik={formik}
                                productsSegregation={productsSegregation}
                                categoryAcc={categoryAcc}
                                originalAmt={amt}
                                onSuccessTransaction={gotoReceipt}
                            />
                        </Box>
                    </Container >
                </>
            )
        } else {
            return (
                <>
                    {/* <Typography sx={{ mx: { xs: "16px", sm: "30px" }, pb: "24px" }} variant="h6">Select payment method</Typography> */}
                    {
                        categoryAcc != "GeneralInvestment" ? (
                            <CustomBoxTab contentTabs={contentTabs}
                                // customTabs={{ mx: { xs: "16px", sm: "30px" } }}
                                scrollBtn={false} />
                        ) : (
                            <Container maxWidth="lg" sx={{ px: "0px!important" }}>
                                <BankDepositSavePlusV2
                                    banksList={banksList}
                                    paymentAccList={paymentAcc}
                                    previousStep={previousStep}
                                    goalId={goalId}
                                    formik={formik}
                                    productsSegregation={productsSegregation}
                                    originalAmt={amt}
                                    categoryAcc={categoryAcc}
                                    receiptCategory={receiptCategory}
                                    onSuccessTransaction={gotoReceipt}
                                />
                            </Container>
                        )
                    }

                </>
            )
        }
    }

    const handleProductsSegregation = () => {

        const onSuccess = (message: string, response: any) => {
            setProductsSegregation(message)
        }
        dispatch(onGetInvestProductsValue("productsSegregation", amt, goalPostId, onSuccess))
    }

    useEffect(() => {
        if (loc?.category == "GeneralInvestment" || categoryAcc == "GeneralInvestment") {
            handleProductsSegregation()
        }

    }, [])

    const paymentRoute = ["/payment"]
    const isPaymentRoute = paymentRoute.includes(location.pathname);
    const mdDownScreen = useMediaQuery(theme.breakpoints.down('md'));
    const addExtraRoutes = [
        {
            title: mdDownScreen ? "Exit" : "Exit",
            route: null,
            icon: ExitIcon,
            isNavlink: false,
            navDropDown: [],
            target: false,
            onClick: () => push("/"),
            functionTrigger: true
        },
    ]

    return (
        <>
            <Box sx={{ backgroundColor: "#F8FAFB", minHeight: isPathInList ? "93vh" : "100vh" }}>
                {!isPathInList && <TopBarLogo addExtraRoutes={addExtraRoutes} />}
                <Container maxWidth={'lg'}
                    sx={{ py: { xs: "24px", md: "40px" }, px: { xs: 0 }, backgroundColor: "#F8FAFB" }}
                    disableGutters={smScreen}>

                    <Box sx={{
                        borderRadius: "3px 3px 8px 8px",
                        background: "#FFF",
                        boxShadow: "0px 1px 4px 0px rgba(208, 213, 221, 0.25)",
                        padding: { xs: "16px", md: "24px 40px" }
                    }}>
                        {categoryAcc != "GeneralInvestment" &&
                            <Typography variant='h1' color={"#1D2939"} textAlign={"center"} mb="24px">Deposit money</Typography>
                        }

                        {productsSegregationLoading ?
                            loadingSkeleton()
                            :
                            handlePayNowScreen()}

                    </Box>
                </Container>

                {/* {<Container disableGutters maxWidth={"lg"}>
                <TopBarHeading heading={"Pay now"} handleBackArrow={onClickBackBtn} chipTitle={goalName} />
                {productsSegregationLoading ?
                    loadingSkeleton()
                    :
                    handlePayNowScreen()}
            </Container>} */}
            </Box>
        </>
    )

}

const loadingSkeleton = () => {
    return (
        <Container maxWidth="lg" sx={{ px: "0px!important" }}>
            <Box sx={{ p: { xs: "16px", sm: "30px", display: "flex", flexDirection: "column", gap: "20px" } }}>
                <Skeleton variant="rounded" width={"30%"} height={15} />
                <Skeleton variant="rounded" width={"100%"} height={40} sx={{ borderRadius: "8px" }} />
                <Divider />
                <Skeleton variant="rounded" width={"100%"} height={80} />
                <Skeleton variant="rounded" width={"100%"} height={200} />
                <Box sx={{ display: 'flex', gap: "20px" }}>
                    <Skeleton variant="rounded" width={"50%"} height={40} />
                    <Skeleton variant="rounded" width={"50%"} height={40} />
                </Box>
            </Box>
        </Container>
    )
}

export default PayNow
interface OnlineBankFormValues {
    accountNumber: string;
    accountTitle: string;
    bank: null;
    proofOfPayment: File | string;
    bankID: string;
    transactionID: string
}

export interface paymentOptionProps {
    banksList: Array<Field>;
    submitBankTransfer?: (data: bankTransferPost) => void;
    paymentAccList: any,
    previousStep?: any,
    selectedBank?: any,
    iban?: any,
    goalId?: any,
    formik?: any,
}